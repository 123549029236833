import React from 'react';
import classNames from 'classnames';
import ReactForm from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import { withTranslation } from 'react-i18next';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';

import { rawValidator } from '@SUPPORT/validator';
import { WithUserTag } from '../../common/WithUserTag';

const GROUP_INFO = {
    '': '-',
    A: 'A',
    Ap: 'A provisoire',
    B: 'B',
    Bp: 'B provisoire',
    C: 'C',
    NC: 'NC',
    ecl: 'À éclipses'
};

function GroupOption(props) {
    return <option value={props.code}>{GROUP_INFO[props.code]}</option>;
}

const GroupEditor = observer((props) => {
    return (
        <div>
            <label>{props.label}</label>
            <select {...props.form.select(props.formKey).bind()}>
                <GroupOption code="" />
                <GroupOption code="A" />
                <GroupOption code="Ap" />
                <GroupOption code="B" />
                <GroupOption code="C" />
                <GroupOption code="NC" />
                <GroupOption code="ecl" />
            </select>
        </div>
    );
});

@inject('store')
@withTranslation(['admin', 'common'])
@observer
class ZoneDialog extends React.Component {
    @observable saving = false;
    firstInput = React.createRef();

    constructor(props) {
        super(props);

        const { user } = this.props.store.session.info;
        const fields = {
            code: {
                type: 'text',
                rules: `required|between:1,10|${props.creation ? 'uniqueCode' : 'uniqueCodeButSelf'}`,
                value: props.creation ? null : props.zone.code
            },
            name: {
                type: 'text',
                rules: 'required|string',
                value: props.creation ? null : props.zone.name
            }
        };

        if (user.tags && user.tags.length > 1) {
            fields.tag = {
                value: props.zone ? (props.zone.tags || [])[0] || '-' : '-'
            };
        }

        if (user.hasTag('pap')) {
            const meta = props.zone ? JSON.parse(props.zone.meta || '{"pap":{}}') : { pap: {} };
            const rules = (props.zone && props.zone.hasTag('pap')) || this.props.creation ? 'required' : undefined;
            fields.group1 = { rules, value: meta.pap && meta.pap.group1 };
            fields.group2 = { rules, value: meta.pap && meta.pap.group2 };
            fields.group3 = { rules, value: meta.pap && meta.pap.group3 };
        }

        this.form = new ReactForm(
            { fields },
            {
                plugins: {
                    dvr: dvr({
                        package: rawValidator,
                        extend: ({ validator }) => {
                            validator.register(
                                'uniqueCode',
                                (value) => {
                                    return !props.store.zones.hasZoneWithCode(value);
                                },
                                ''
                            );

                            validator.register(
                                'uniqueCodeButSelf',
                                (value) => {
                                    return value === props.zone.code || !props.store.zones.hasZoneWithCode(value);
                                },
                                ''
                            );
                        }
                    })
                },
                options: { validateOnChange: true }
            }
        );
    }

    @action.bound
    _setSaving(saving = true) {
        this.saving = saving;
    }

    componentDidMount() {
        if (this.firstInput.current) {
            this.firstInput.current.focus();
        }
    }

    _saveZone = (evt) => {
        evt.preventDefault();

        const { org } = this.props.store.session.info;

        const code = this.form.select('code').value;
        const name = this.form.select('name').value;
        const meta = org.hasTag('pap')
            ? JSON.stringify({
                  pap: {
                      group1: this.form.select('group1').value,
                      group2: this.form.select('group2').value,
                      group3: this.form.select('group3').value
                  }
              })
            : '';

        const promise = this.props.creation
            ? this.props.store.zones.createZone(code, name, meta)
            : this.props.store.zones.updateZone(this.props.zone.id, code, name, meta);

        this._setSaving();
        promise.then(() => {
            this._dismiss();
            this._setSaving(false);
        });
    };

    _dismiss = () => {
        this.props.dismiss();
    };

    render() {
        const codeFldClasses = classNames({
            codeRed: this.form.select('code').isDirty && !this.form.select('code').isValid
        });
        const nameFldClasses = classNames({
            codeRed: this.form.select('name').isDirty && !this.form.select('name').isValid
        });

        const { t } = this.props;
        return (
            <form className="show">
                <div className="block">
                    <div>
                        <label>{t('common:code')}</label>
                        <input {...this.form.select('code').bind()} className={codeFldClasses} ref={this.firstInput} />
                    </div>
                    <div>
                        <label>{t('admin:zones-section.zone-name')}</label>
                        <input {...this.form.select('name').bind()} className={nameFldClasses} />
                    </div>
                    <WithUserTag tag="pap">
                        <GroupEditor label="Groupe 1" form={this.form} formKey="group1" />
                        <GroupEditor label="Groupe 2" form={this.form} formKey="group2" />
                        <GroupEditor label="Groupe 3" form={this.form} formKey="group3" />
                    </WithUserTag>

                    <div className="exit">
                        <button onClick={this._dismiss} disabled={this.saving}>
                            {t('common:cancel')}
                        </button>
                        <button
                            onClick={this._saveZone}
                            disabled={!(this.form.isDirty && this.form.isValid) || this.saving}
                        >
                            {this.props.creation ? t('common:create') : t('common:save')}
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

@inject('store')
@withTranslation(['admin', 'common'])
@observer
export class ZonesSection extends React.Component {
    @observable _showDialog = false;
    @observable _dialogMode = 'create';

    _editedZone = null;

    _openDialogForCreation = () => {
        this._openDialog('create');
    };

    _openDialogForEdition = (zone) => {
        this._editedZone = zone;
        this._openDialog('edit');
    };

    @action.bound
    _openDialog(mode) {
        this._dialogMode = mode;
        this._showDialog = true;
    }

    @action.bound
    _closeDialog() {
        this._showDialog = false;
        this._editedZone = null;
    }

    render() {
        const { user } = this.props.store.session.info;
        const { t } = this.props;
        return (
            <div className="selected">
                <div className="header">
                    <h3>{t('common:zones-full')}</h3>
                </div>
                <div className={classNames('scroll', 'withPopUpBox', { showPopupBox: this._showDialog })}>
                    <table>
                        <thead>
                            <tr>
                                <th>{t('common:code')}</th>
                                <th>{t('admin:zones-section.zone-name')}</th>
                                <WithUserTag tag="pap">
                                    <th>Groupe 1</th>
                                    <th>Groupe 2</th>
                                    <th>Groupe 3</th>
                                </WithUserTag>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.store.zones.list.map((zone) => {
                                const meta = JSON.parse(zone.meta || '{"pap":{}}');
                                return (
                                    <tr
                                        key={zone.id}
                                        onClick={user.isNotRestricted ? () => this._openDialogForEdition(zone) : null}
                                    >
                                        <td>
                                            <span>{t('common:code')} :</span>
                                            {zone.code}
                                        </td>
                                        <td>
                                            <span>{t('admin:zones-section.zone-name')} :</span>
                                            {zone.name}
                                        </td>
                                        <WithUserTag tag="pap">
                                            <td>
                                                <span>Groupe 1 :</span>
                                                {GROUP_INFO[(meta.pap && meta.pap.group1) || ''] || '-'}
                                            </td>
                                            <td>
                                                <span>Groupe 2 :</span>
                                                {GROUP_INFO[(meta.pap && meta.pap.group2) || ''] || '-'}
                                            </td>
                                            <td>
                                                <span>Groupe 3 :</span>
                                                {GROUP_INFO[(meta.pap && meta.pap.group3) || ''] || '-'}
                                            </td>
                                        </WithUserTag>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    {this._showDialog && (
                        <ZoneDialog
                            creation={this._dialogMode === 'create'}
                            zone={this._editedZone}
                            dismiss={this._closeDialog}
                        />
                    )}
                </div>
                <div className="exit">
                    <div>
                        {user.isNotRestricted && (
                            <button onClick={this._openDialogForCreation}>{t('admin:zones-section.new-zone')}</button>
                        )}
                    </div>
                    <div />
                </div>
            </div>
        );
    }
}
