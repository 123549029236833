import { types, getRoot, flow } from 'mobx-state-tree';

import { api } from '@SUPPORT/api';

const ErrorType = types.enumeration('ErrorType', [
    'unknown_person',
    'unknown_org',
    'unknown_zone',
    'disallowed_zone',
    'unknown_species',
    'disallowed_species',
    'unknown_gear',
    'missing_gear',
    'missing_cr',
    'too_many_decl',
    'out_of_season',
    'malformed_text',
    'low_value',
    'high_value',
    'total_value_exceeded',
    'transaction_processed',
    'closed_zone',
    'other'
]);

const DeclarationType = types.enumeration('DeclarationType', ['sms', 'web', 'api']);

const DeclarationError = types
    .model('Error', {
        id: types.identifierNumber,
        type: ErrorType,
        declarationType: DeclarationType,
        eventDate: types.Date,
        sourceText: types.string,
        userId: types.number,
        usersFirstName: types.string,
        usersLastName: types.string,
        usersMobileNumber: types.maybeNull(types.string),
        usersPhoneNumber: types.maybeNull(types.string)
    })
    .preProcessSnapshot((snapshot) => ({
        ...snapshot,
        eventDate: typeof snapshot.eventDate === 'string' ? new Date(snapshot.eventDate) : snapshot.eventDate
    }));

export const ErrorsOverviewStore = types
    .model('ErrorsOverviewStore', {
        limit: 10,
        list: types.array(DeclarationError)
    })
    .actions((self) => {
        const { app, session } = getRoot(self);

        return {
            setLimit: (limit) => {
                self.limit = limit;
                self.listErrors();
            },

            listErrors: flow(function* () {
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    const response = yield api.listOrgErrors(info.org.id, self.limit);
                    self.list = response.data.map((data) => DeclarationError.create(data));
                    return self.list;
                } finally {
                    app.setBusy(false);
                }
            })
        };
    });
