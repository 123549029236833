import { types, getRoot, flow } from 'mobx-state-tree';

import { api } from '@SUPPORT/api';

import { BuyerOrgList } from '@STORES/common/buyer-org.js';
import { TransactionList } from '@STORES/common/transaction';

export const BuyersStore = types
    .model('BuyersStore', {
        list: types.optional(BuyerOrgList, []),
        listLoaded: false
    })
    .actions((self) => {
        const { app } = getRoot(self);

        return {
            listAll: flow(function* (force = false) {
                if (self.listLoaded && !force) {
                    return self.list;
                }

                app.setBusy();
                try {
                    const response = yield api.listBuyerOrgs();
                    self.list = response.data ? BuyerOrgList.create(response.data) : [];
                    self.listLoaded = true;

                    return self.list;
                } finally {
                    app.setBusy(false);
                }
            }),

            listTransactions: flow(function* (orgId) {
                app.setBusy();
                try {
                    const response = yield api.listBuyerOrgTransactions(orgId);
                    return TransactionList.create(response.data || []);
                } finally {
                    app.setBusy(false);
                }
            })
        };
    });
