import { types, flow } from 'mobx-state-tree';
import { addDays, isBefore } from 'date-fns';

import { api } from '@SUPPORT/api';
import { i18n } from '@SUPPORT/i18n';

export const Transaction = types
    .model('Transaction', {
        id: types.identifierNumber,
        status: types.enumeration('TransactionStatus', ['pending', 'accepted', 'updated', 'rejected']),
        creationDate: types.Date,
        validationDate: types.maybeNull(types.Date),
        price: types.maybeNull(types.number),
        unit: types.maybeNull(types.string),
        currency: types.maybeNull(types.string),
        adjustedValue: types.maybeNull(types.number),
        declarationId: types.number,
        buyerOrgId: types.number,
        buyerId: types.maybeNull(types.number),
        fishingCard: types.maybeNull(types.string),
        groupName: types.maybeNull(types.string),

        buyerFirstName: types.string,
        buyerLastName: types.string,
        declarationValue: types.number,
        declarationZoneName: types.string,
        declarationSpecies: types.string,
        declarationIdentifier: types.string,
        declarationFirstName: types.string,
        declarationLastName: types.string,
        declarationBoatName: types.maybeNull(types.string),
        declarationBoatRegistration: types.maybeNull(types.string),
        declarationUserId: types.number,
        declarationTags: types.maybeNull(types.array(types.string))
    })
    .preProcessSnapshot(
        (snapshot) =>
            snapshot && {
                ...snapshot,
                unit: snapshot.unit ? snapshot.unit.trim() : snapshot.unit,
                creationDate:
                    typeof snapshot.creationDate === 'string' ? new Date(snapshot.creationDate) : snapshot.creationDate,
                validationDate:
                    typeof snapshot.validationDate === 'string'
                        ? new Date(snapshot.validationDate)
                        : snapshot.validationDate
            }
    )
    .views((self) => {
        return {
            qualityLabel: () => {
                switch (self.quality) {
                    case 'very-good':
                        return i18n.t('common:quality-level.very-good');
                    case 'good':
                        return i18n.t('common:quality-level.good');
                    case 'ok':
                        return i18n.t('common:quality-level.ok');
                    case 'bad':
                        return i18n.t('common:quality-level.bad');
                    case 'very-bad':
                        return i18n.t('common:quality-level.very-bad');
                    default:
                        return '-';
                }
            },

            canEditFishingCard: () => {
                if (!self.validationDate) {
                    return false;
                }

                const twoDaysAfterValidation = addDays(self.validationDate, 2);
                return isBefore(new Date(), twoDaysAfterValidation);
            }
        };
    })
    .actions((self) => {
        return {
            setStatus(status) {
                self.status = status;
            },

            update(fishingCardNum, priceInfo, value, status) {
                self.fishingCard = fishingCardNum;
                self.price = priceInfo.price;
                self.unit = priceInfo.unit;
                self.currency = priceInfo.currency;
                self.adjustedValue = value;
                self.status = status;
            },

            setGroup(group) {
                self.groupName = group;
            },

            updateFishingCard: flow(function* (fishingCardNum) {
                yield api.updateTransaction(
                    self.id,
                    self.buyerId,
                    fishingCardNum,
                    { price: self.price, unit: self.unit, currency: self.currency },
                    self.adjustedValue,
                    null,
                    self.status
                );
                self.fishingCard = fishingCardNum;
            })
        };
    });

export const TransactionList = types.array(Transaction);
