import React from 'react';

export class ConflictsSection extends React.Component {
    render() {
        return (
            <div className="selected">
                <div className="header">
                    <h3>Conflits</h3>
                    <form>
                        <div className="aligned">
                            <label>Afficher les</label>
                            <select>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                            </select>
                            <label>derniers conflits</label>
                        </div>
                    </form>
                </div>
                <div className="scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Pêcheur</th>
                                <th>Acheteur</th>
                                <th>Fisherman report</th>
                                <th>Adjusted weight</th>
                                <th>Difference</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span>Date:</span>Feb 12th, 2017
                                </td>
                                <td>
                                    <span>Fisherman:</span>CHOLET Michel
                                </td>
                                <td>
                                    <span>Buyer:</span>Peter Neusinger Corp
                                </td>
                                <td className="codeRed">
                                    <span>Fisherman report:</span>125 grams
                                </td>
                                <td>
                                    <span>Adjusted weight:</span>122g
                                </td>
                                <td className="codeRed">
                                    <span>Difference:</span>-3g
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
