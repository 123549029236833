import React from 'react';
import { inject } from 'mobx-react';

export const GearsList = inject('store')((props) => {
    const { gears } = props;

    if (gears.length === 0) {
        return null;
    }

    if (gears.length === 1) {
        const gear = gears[0];
        return (
            <React.Fragment>
                <i className="red" title={gear.name} key={gear.id}>
                    {gear.code}
                </i>
                {gear.name}
            </React.Fragment>
        );
    }

    return gears.map((gear) => (
        <i className="red" title={gear.name} key={gear.id}>
            {gear.code}
        </i>
    ));
});
