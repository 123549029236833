import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Route, Redirect, withRouter } from 'react-router-dom';

import { SelectorLink } from '@COMPONENTS/common/SelectorLink';
import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

@withRouter
@inject('store')
@withTranslation(['common'])
@observer
export class SpeciesSelector extends React.Component {
    _update = () => {
        this.props.store.species.listOrgSpecies();
    };

    render() {
        const selectors = this.props.store.species.orgList.map((species) => {
            const link = `${this.props.baseUrl}/${species.code}`;
            const declarationsCount = this.props.store.species.orgDecls.get(species.code);
            return (
                <Route
                    render={() => (
                        <SelectorLink
                            className={classNames({
                                disabled: this.props.disableWhenNoDecl && declarationsCount === 0
                            })}
                            label={species.name}
                            capsule={species.code}
                            capsuleClass="yellow"
                            to={link}
                        />
                    )}
                    key={species.code}
                />
            );
        });

        const { orgList } = this.props.store.species;
        const firstSpecies = orgList.length > 0 ? orgList[0] : null;
        const defaultSelection = firstSpecies ? `${this.props.baseUrl}/${firstSpecies.code}` : null;

        const { t, baseUrl, allowCreation } = this.props;
        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="list subList show">
                    <dl className="show">
                        <div className="burger">
                            <span />
                        </div>
                        <h6>{t('common:all-species')}</h6>
                        <dt>{t('common:species-plural')}</dt>
                        {selectors}
                        {defaultSelection ? (
                            <Route exact path={baseUrl} render={() => <Redirect to={defaultSelection} />} />
                        ) : null}
                    </dl>
                    <ul className="toolbar">{allowCreation ? <li className="plus" /> : null}</ul>
                </div>
            </OrgChangeObserver>
        );
    }
}
