import React from 'react';
import { inject } from 'mobx-react';

@inject('store')
export class WithUserLevel extends React.Component {
    render() {
        if (this.props.store.session.info.user.type !== this.props.level) {
            return null;
        }

        return this.props.children;
    }
}
