import React from 'react';
import classNames from 'classnames';
import ReactForm from 'mobx-react-form';
import { observable, action, reaction } from 'mobx';
import { inject, observer, disposeOnUnmount } from 'mobx-react';
import { parse as parseDate, startOfYear, endOfYear } from 'date-fns';

import { validator } from '@SUPPORT/validator';
import { formatLocaleDate } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@inject('store')
@observer
export class RuleDialog extends React.Component {
    @observable form = null;
    @observable busy = false;

    _speciesSelect = React.createRef();
    _gearsSelect = React.createRef();

    componentDidMount() {
        disposeOnUnmount(
            this,
            reaction(
                () => this.props.store.rules.showRuleDialog,
                () => this._syncForm()
            )
        );
    }

    @action setBusy(busy = true) {
        this.busy = busy;
    }

    @action.bound _syncForm() {
        const { store } = this.props;
        if (!store.rules.showRuleDialog) {
            this.form = null;
            return;
        }

        const fields = {
            zoneId: {
                rules: 'required',
                value: store.rules.ruleDialogData ? store.rules.ruleDialogData.zone.id : ''
            },
            species: {
                rules: 'array|required',
                value: store.rules.ruleDialogData ? store.rules.ruleDialogData.species.map((sp) => sp.code) : []
            }
        };

        if (store.rules.ruleDialogType === 'opening' || store.rules.ruleDialogType === 'quota') {
            fields.startDate = {
                rules: 'fdate' + store.rules.ruleDialogType === 'opening' ? '|required' : '',
                placeholder: 'jj/mm/aaaa',
                value:
                    store.rules.ruleDialogData && store.rules.ruleDialogData.info.startDate
                        ? formatLocaleDate(store.rules.ruleDialogData.info.startDate, 'P')
                        : ''
            };
            fields.endDate = {
                rules: 'fdate' + store.rules.ruleDialogType === 'opening' ? '|required' : '',
                placeholder: 'jj/mm/aaaa',
                value:
                    store.rules.ruleDialogData && store.rules.ruleDialogData.info.endDate
                        ? formatLocaleDate(store.rules.ruleDialogData.info.endDate, 'P')
                        : ''
            };
        }
        if (store.rules.ruleDialogType === 'quota') {
            fields.dailyQuota = {
                rules: 'integer|required_without:totalQuota',
                value: store.rules.ruleDialogData ? store.rules.ruleDialogData.info.dailyQuota : '',
                hooks: { onChange: () => this.form.validate() }
            };
            fields.totalQuota = {
                rules: 'integer|required_without:dailyQuota',
                value: store.rules.ruleDialogData ? store.rules.ruleDialogData.info.totalQuota : '',
                hooks: { onChange: () => this.form.validate() }
            };
        } else if (store.rules.ruleDialogType === 'gear') {
            fields.excludedGears = {
                rules: 'array|required',
                value: store.rules.ruleDialogData
                    ? store.rules.ruleDialogData.info.excludedGears.map((g) => g.id.toString())
                    : []
            };
        }

        this.form = new ReactForm(
            { fields },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    _handleMultiSelect = (field, selectRef) => () => {
        const select = selectRef.current;
        if (!select) {
            return;
        }

        const { options } = select;
        if (options.item(0).selected) {
            this.form.select(field).set(['***']);
            return;
        }

        const value = [];
        for (let i = 0; i < options.length; i++) {
            const option = options.item(i);
            if (option.selected) {
                value.push(option.value);
            }
        }

        this.form.select(field).set(value);
    };

    _handleSpeciesMultiselect = this._handleMultiSelect('species', this._speciesSelect);
    _handleGearsMultiselect = this._handleMultiSelect('excludedGears', this._gearsSelect);

    _create = () => {
        const { store } = this.props;
        const values = this.form.values();
        const now = new Date();

        let species = values.species;
        if (species.length === store.species.orgList.length) {
            species = ['***'];
        }

        const data = {
            zone: parseInt(values.zoneId),
            species: species,
            info: JSON.stringify({
                ...(store.rules.ruleDialogType === 'opening'
                    ? {
                          startDate: parseDate(values.startDate, 'dd/MM/yyyy', startOfYear(now)),
                          endDate: parseDate(values.endDate, 'dd/MM/yyyy', endOfYear(now))
                      }
                    : {}),

                ...(store.rules.ruleDialogType === 'quota'
                    ? {
                          startDate: values.startDate
                              ? parseDate(values.startDate, 'dd/MM/yyyy', startOfYear(now))
                              : null,
                          endDate: values.endDate ? parseDate(values.endDate, 'dd/MM/yyyy', endOfYear(now)) : null,
                          dailyQuota: parseInt(values.dailyQuota, 10),
                          totalQuota: parseInt(values.totalQuota, 10)
                      }
                    : {}),

                ...(store.rules.ruleDialogType === 'gear'
                    ? {
                          excludedGears: values.excludedGears.map((id) => parseInt(id, 10))
                      }
                    : {})
            })
        };

        if (store.rules.ruleDialogData) {
            data.id = store.rules.ruleDialogData.id;
        }

        this.setBusy();

        const promise = store.rules.ruleDialogData
            ? store.rules.updateRule(store.rules.ruleDialogType, store.rules.ruleDialogData.id, data)
            : store.rules.createRule(store.rules.ruleDialogType, data);

        promise
            .then(() => {
                this.setBusy(false);
                this._dismiss();
            })
            .catch((err) => {
                console.log(err);
                this.setBusy(false);
            });
    };

    _delete = () => {
        const ok = confirm('Voulez-vous réellement supprimer cette règle ?');
        if (!ok) {
            return;
        }

        const { store } = this.props;
        const ruleType = store.rules.ruleDialogType;
        const ruleId = store.rules.ruleDialogData.id;

        this._dismiss();

        store.rules.deleteRule(ruleType, ruleId).catch((err) => console.log(err));
    };

    _dismiss = () => {
        this.props.store.rules.setShowRuleDialog(false);
    };

    render() {
        const { store } = this.props;
        return (
            <div className={classNames('modal', { show: store.rules.showRuleDialog })}>
                <div className="sheet">
                    <div className="title">
                        <h3>{store.rules.ruleDialogData ? 'Modifier la règle' : 'Nouvelle règle'}</h3>
                        <SVGObject objectId="exitCross" onClick={this._dismiss} />
                    </div>
                    <div className="scroll">
                        {this.form && (
                            <form>
                                <div className="required">
                                    <label>Zone de pêche :</label>
                                    <select {...this.form.select('zoneId').bind()}>
                                        <option>Sélectionner...</option>
                                        {store.zones.list.map((z) => (
                                            <option value={z.id} key={z.id}>
                                                {z.code} - {z.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="required">
                                    <label>Espèce(s) :</label>
                                    <select
                                        ref={this._speciesSelect}
                                        value={this.form.select('species').value}
                                        onChange={this._handleSpeciesMultiselect}
                                        multiple
                                    >
                                        <option value="***">Toutes les espèces</option>
                                        {store.species.orgList.map((sp) => (
                                            <option value={sp.code} key={sp.code}>
                                                {sp.code} - {sp.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {store.rules.ruleDialogType === 'opening' && (
                                    <React.Fragment>
                                        <div className="required">
                                            <label>Date de début :</label>
                                            <input {...this.form.select('startDate').bind()} className="date" />
                                        </div>
                                        <div className="required">
                                            <label>Date de fin :</label>
                                            <input {...this.form.select('endDate').bind()} className="date" />
                                        </div>
                                    </React.Fragment>
                                )}

                                {store.rules.ruleDialogType === 'quota' && (
                                    <React.Fragment>
                                        <div>
                                            <label>Date de début :</label>
                                            <input {...this.form.select('startDate').bind()} className="date" />
                                        </div>
                                        <div>
                                            <label>Date de fin :</label>
                                            <input {...this.form.select('endDate').bind()} className="date" />
                                        </div>
                                        <div
                                            className={classNames('aligned', {
                                                required:
                                                    !this.form.select('totalQuota').isDirty ||
                                                    (this.form.select('totalQuota').isDirty &&
                                                        !this.form.select('totalQuota').isValid)
                                            })}
                                        >
                                            <label>Quota indiv. / jour :</label>
                                            <input
                                                {...this.form.select('dailyQuota').bind()}
                                                className="mid"
                                                style={{ marginRight: 4 }}
                                            />
                                            <label>kg</label>
                                        </div>
                                        <div
                                            className={classNames('aligned', {
                                                required:
                                                    !this.form.select('dailyQuota').isDirty ||
                                                    (this.form.select('dailyQuota').isDirty &&
                                                        !this.form.select('dailyQuota').isValid)
                                            })}
                                        >
                                            <label>Quota total :</label>
                                            <input
                                                {...this.form.select('totalQuota').bind()}
                                                className="mid"
                                                style={{ marginRight: 4 }}
                                            />
                                            <label>kg</label>
                                        </div>
                                    </React.Fragment>
                                )}

                                {store.rules.ruleDialogType === 'gear' && (
                                    <div className="required">
                                        <label>Engin(s) interdit(s) :</label>
                                        <select
                                            ref={this._gearsSelect}
                                            value={this.form.select('excludedGears').value}
                                            onChange={this._handleGearsMultiselect}
                                            multiple
                                        >
                                            {/*<option value="***">Tous les engins</option>*/}
                                            {store.gears.tagged(['pap']).map((g) => (
                                                <option value={g.id} key={g.id}>
                                                    {g.code || '???'} - {g.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                            </form>
                        )}
                    </div>
                    <div className="exit">
                        <div>
                            {store.rules.ruleDialogData && (
                                <button className="codeRed" disabled={this.busy} onClick={this._delete}>
                                    Supprimer
                                </button>
                            )}
                        </div>
                        <div>
                            <button onClick={this._dismiss} disabled={this.busy}>
                                Annuler
                            </button>
                            <button
                                onClick={this._create}
                                disabled={
                                    this.busy ||
                                    (this.form && (!this.form.isDirty || (this.form.isDirty && !this.form.isValid)))
                                }
                            >
                                {store.rules.ruleDialogData ? 'Modifier' : 'Créer'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
