import React from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';

import { BuyerInfoSubSection } from './BuyerInfoSubSection';
import { BuyerTransactionsSubSection } from './BuyerTransactionsSubSection';

import { NavigationLink } from '@COMPONENTS/common/NavigationLink';

@inject('store')
@withTranslation(['common'])
@observer
export class BuyersSection extends React.Component {
    render() {
        const buyers = this.props.store.buyers.list || [];
        const buyer = buyers.find((buyer) => buyer.id === parseInt(this.props.match.params.id, 10));
        if (!buyer) {
            return null;
        }

        const { t } = this.props;
        return (
            <div className="selected">
                <div className="header noBottom">
                    <h3>
                        {buyer.name}
                        <span>{t('common:buyer')}</span>
                    </h3>
                    <nav>
                        <ul>
                            <NavigationLink to={`/people/buyer/${buyer.id}/info`} label={t('common:infos')} tag="li" />
                            <NavigationLink
                                to={`/people/buyer/${buyer.id}/transactions`}
                                label={t('common:transactions')}
                                tag="li"
                            />
                        </ul>
                    </nav>
                </div>
                <div className="panels">
                    <Route path="/people/buyer/:id/info" render={() => <BuyerInfoSubSection buyer={buyer} />} />
                    <Route
                        path="/people/buyer/:id/transactions"
                        render={() => <BuyerTransactionsSubSection buyer={buyer} />}
                    />
                    <Route
                        exact
                        path="/people/buyer/:id"
                        render={() => <Redirect to={`/people/buyer/${buyer.id}/info`} />}
                    />
                </div>
            </div>
        );
    }
}
