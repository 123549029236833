import React from 'react';

export class GroupsSelector extends React.Component {
    render() {
        return (
            <div className="list subList show">
                <dl className="show">
                    <div className="burger">
                        <span />
                    </div>
                    <h6>OP</h6>
                    <dt>Groups</dt>
                    <dd>OP</dd>
                </dl>
                <ul className="toolbar">
                    <li />
                </ul>
            </div>
        );
    }
}
