import React from 'react';
import { inject, observer } from 'mobx-react';

import ReactForm from 'mobx-react-form';
import { validator } from '@SUPPORT/validator';

const months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
];

const monthsOf = [
    'de Janvier',
    'de Février',
    'de Mars',
    'de Avril',
    'de Mai',
    'de Juin',
    'de Juillet',
    "d'Août",
    'de Septembre',
    "d'Octobre",
    'de Novembre',
    'de Décembre'
];

function formatSampleMessage(template) {
    const now = new Date();
    const currentMonth = now.getMonth();
    const previousMonth = currentMonth === 0 ? 12 : currentMonth - 1;

    return template
        .replace('{{.PM}}', months[previousMonth])
        .replace('{{.CM}}', months[currentMonth])
        .replace('{{.OPM}}', monthsOf[previousMonth])
        .replace('{{.OCM}}', monthsOf[currentMonth]);
}

@inject('store')
@observer
export class AlertsSection extends React.Component {
    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    day: { type: 'text', rules: 'required|numeric|between:1,31', placeholder: 'dd' },
                    hour: { type: 'text', rules: 'required|digits:2|numeric|between:0,23', placeholder: 'hh' },
                    minute: { type: 'text', rules: 'required|digits:2|numeric|between:0,59', placeholder: 'mm' },
                    message: { rules: 'required|string' }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    componentDidMount() {
        this.props.store.settings.fetch('alerts', null, 'pap').then((settings) => {
            let hour, minute;
            const match = settings.time.match(/(\d+):(\d+)/);
            if (match) {
                hour = match[1];
                minute = match[2];
            }

            this._initWithData({ ...settings, hour, minute });
        });
    }

    _initWithData = (data) => {
        this.form.select('day').set('default', data.day);
        this.form.select('hour').set('default', data.hour);
        this.form.select('minute').set('default', data.minute);
        this.form.select('message').set('default', data.message);
        this.form.reset();
    };

    _reset = (evt) => {
        evt.preventDefault();
        this.form.reset();
    };

    _save = (evt) => {
        evt.preventDefault();
        const values = this.form.values();
        const data = {
            day: parseInt(values.day, 10),
            time: `${values.hour}:${values.minute}`,
            message: values.message
        };

        this.props.store.settings.update('alerts', null, 'pap', data).then(() => {
            this._initWithData(values);
        });
    };

    render() {
        const message = formatSampleMessage(this.form.select('message').value);
        return (
            <div className="selected">
                <div className="header">
                    <h3>Alertes</h3>
                </div>
                <div className="scroll">
                    <form>
                        <div className="full">
                            <p>
                                Le message d&apos;alerte sert à rappeler aux pêcheurs qu&apos;ils doivent valider leur
                                fiche de pêche avant une date limite. A la date spécifiée ici, un message sera envoyé
                                automatiquement par SMS aux pêcheurs qui n&apos;auront pas validé.
                            </p>
                        </div>
                        <h4>Date</h4>
                        <div>
                            <label>Jour de l&apos;alerte :</label>
                            <div className="together">
                                <label>Le</label>
                                <input className="twoDigits" {...this.form.select('day').bind()} />
                                <label>du mois</label>
                            </div>
                        </div>
                        <div>
                            <label>Heure de l&pos;alerte :</label>
                            <div className="together">
                                <input className="twoDigits" {...this.form.select('hour').bind()} />
                                <label>h</label>
                                <input className="twoDigits" {...this.form.select('minute').bind()} />
                                <label>mn</label>
                            </div>
                        </div>
                        <h4>Message</h4>
                        <div className="full">
                            <label>Texte de l&apos;alerte :</label>
                            <textarea rows="3" {...this.form.select('message').bind()} />
                        </div>
                        <div className="full">
                            <label>Résultat envoyé aux pêcheurs [{message.length} car.]:</label>
                            <p>
                                <b>{message}</b>
                            </p>
                        </div>
                    </form>
                </div>
                <div className="exit">
                    <div />
                    <div>
                        <button onClick={this._reset} disabled={!this.form.isDirty}>
                            Reset
                        </button>
                        <button onClick={this._save} disabled={!this.form.isValid || !this.form.isDirty}>
                            Sauver
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
