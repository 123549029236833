import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { Route, Redirect } from 'react-router-dom';

import { SelectorLink } from '@COMPONENTS/common/SelectorLink';
import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

@withRouter
@inject('store')
@withTranslation(['common'])
@observer
export class BuyersSelector extends React.Component {
    _update = () => {
        this.props.store.buyers.listAll();
    };

    render() {
        const buyerOrgsList = this.props.store.buyers.list || [];
        const buyerOrgs = buyerOrgsList.map((buyer) => {
            const link = `/people/buyer/${buyer.id}`;
            const classes = classNames({ disabled: buyer.status === 'inactive' });
            return <SelectorLink label={buyer.name} to={link} className={classes} key={buyer.id} />;
        });

        let defaultRoute = null;
        const firstBuyerOrg = firstBuyerOrg.length > 0 ? buyerOrgsList[0] : 0;
        if (firstBuyerOrg) {
            const defaultSelection = `/people/buyer/${firstBuyerOrg.id}`;
            defaultRoute = <Route exact path="/people/buyer" render={() => <Redirect to={defaultSelection} />} />;
        }

        const { t } = this.props;
        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="list subList show">
                    <dl className="show">
                        <div className="burger">
                            <span />
                        </div>
                        <h6>Gurruchaga Corp</h6>
                        <dt>{t('common:buyers')}</dt>
                        {buyerOrgs}
                        {defaultRoute}
                    </dl>
                    <ul className="toolbar" />
                </div>
            </OrgChangeObserver>
        );
    }
}
