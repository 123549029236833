import React from 'react';
import classNames from 'classnames';
import { parseISO } from 'date-fns';
import { withTranslation } from 'react-i18next';
import { observable, action, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';

import { formatDeclarationValue, formatLocalTimezone, formatLocaleDate } from '@SUPPORT/utils';

import { WithUserTag } from '@COMPONENTS/common/WithUserTag';
import { UnlessUserTag } from '@COMPONENTS/common/UnlessUserTag';
import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

function SeasonSelector(props) {
    const tags = props.seasons.reduce((set, season) => {
        const tag = season.tags ? season.tags[0] : 'none';
        set.add(tag);
        return set;
    }, new Set());

    const seasons = props.seasons.filter((s) => s.declarationsCount > 0);

    return (
        <select value={props.selection || ''} onChange={props.onChange}>
            <option value="">Toutes</option>
            {seasons.map((season) => (
                <option value={season.id} key={season.id}>
                    {season.name} {tags.size > 1 && season.tags && season.tags[0] && `[${season.tags[0]}]`}
                </option>
            ))}
        </select>
    );
}

@inject('store')
@withTranslation(['admin', 'common'])
class CumulativeRow extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <tr className={classNames({ total: this.props.isTotal })}>
                <td>
                    <span>{t('common:zone-full')} :</span>
                    {this.props.isTotal
                        ? t('common:total')
                        : `${this.props.cumulative.code} - ${this.props.cumulative.name}`}
                </td>
                <UnlessUserTag tag="pal">
                    <td>
                        <span>{t('admin:cumulatives.expected-weight')}</span>-
                    </td>
                    <td>
                        <span>{t('admin:cumulatives.current-weight')}</span>
                        {formatDeclarationValue(this.props.cumulative.weightCumulative)}
                    </td>
                </UnlessUserTag>
                <WithUserTag tag="pal">
                    <td>
                        <span>{t('admin:cumulatives.catches')}</span>
                        {this.props.cumulative.declCumulative}
                    </td>
                    <td>
                        <span>{t('admin:cumulatives.meanSize')}</span>
                        {(this.props.cumulative.meanSize || 0).toFixed(2)} cm
                    </td>
                    <td>
                        <span>{t('admin:cumulatives.weight')}</span>
                        {formatDeclarationValue(this.props.cumulative.weightCumulative)}
                    </td>
                </WithUserTag>
                {this.props.species.code === 'EL1' && (
                    <React.Fragment>
                        <td>{formatDeclarationValue(this.props.cumulative.consumption)}</td>
                        <td>{formatDeclarationValue(this.props.cumulative.restocking)}</td>
                    </React.Fragment>
                )}
            </tr>
        );
    }
}

@withRouter
@inject('store')
@withTranslation(['admin', 'common'])
@observer
export class SpeciesOvwSection extends React.Component {
    @observable seasons = [];
    @observable selectedSeasonId = null;
    @observable cumulatives = { zones: [] };

    componentDidUpdate(prevProps) {
        if (this.props.match.params.code !== prevProps.match.params.code) {
            this.cumulatives = { zones: [] };
            this._update();
        }
    }

    @computed
    get selectedSeasonInfo() {
        if (!this.selectedSeasonId) {
            return '';
        }

        const season = this.seasons.find((s) => s.id.toString() === this.selectedSeasonId);
        if (!season) {
            return '';
        }

        const startDate = parseISO(season.startDate.replace(/Z$/, formatLocalTimezone()));
        const formattedStartDate = formatLocaleDate(startDate, 'P');
        const endDate = parseISO(season.endDate.replace(/Z$/, formatLocalTimezone()));
        const formattedEndDate = formatLocaleDate(endDate, 'P');
        return `(${formattedStartDate} - ${formattedEndDate})`;
    }

    @action.bound
    _update() {
        const speciesCode = this.props.match.params.code;
        this.props.store.speciesOverview
            .listSeasons(speciesCode)
            .then((seasons) => (this.seasons = seasons || []))
            .then(this._updateCumulatives);
    }

    @action.bound
    _updateCumulatives() {
        const speciesCode = this.props.match.params.code;
        this.props.store.speciesOverview
            .listSpeciesCumulatives(speciesCode, this.selectedSeasonId || undefined)
            .then((cumulatives) => {
                this.cumulatives = cumulatives;
            });
    }

    @action.bound
    _selectSeason(evt) {
        this.selectedSeasonId = evt.target.value;
        this._updateCumulatives();
    }

    _downloadCSV = (evt) => {
        evt.preventDefault();
        const speciesCode = this.props.match.params.code;
        this.props.store.speciesOverview.downloadDeclarationsCSV(speciesCode, this.selectedSeasonId || undefined);
    };

    render() {
        const species = this.props.store.species.orgList.find((sp) => sp.code === this.props.match.params.code);
        if (!species) {
            return null;
        }

        const { t } = this.props;
        const cumulativeRows = this.cumulatives.zones.map((c) => (
            <CumulativeRow species={species} cumulative={c} key={c.id} />
        ));
        cumulativeRows.push(
            <CumulativeRow species={species} cumulative={this.cumulatives} key="total" isTotal={true} />
        );

        return (
            <OrgChangeObserver onChange={this._update}>
                <div className=" selected">
                    <div className="header">
                        <h3>
                            {`${species.name} (${species.code})`}
                            <span>
                                {t('common:campaign')} :&nbsp;
                                <SeasonSelector
                                    seasons={this.seasons}
                                    selection={this.selectedSeasonId}
                                    onChange={this._selectSeason}
                                />
                                &nbsp;{this.selectedSeasonInfo}
                            </span>
                        </h3>
                        <form className="noBottom">
                            <div className="aligned">
                                <button onClick={this._downloadCSV}>{t('common:download-csv')}</button>
                            </div>
                        </form>
                    </div>
                    <div className="scroll">
                        <table>
                            <thead>
                                <tr>
                                    <th>{t('common:zone-full')}</th>
                                    <UnlessUserTag tag="pal">
                                        <th>{t('admin:cumulatives.expected-weight')}</th>
                                        <th>{t('admin:cumulatives.current-weight')}</th>
                                    </UnlessUserTag>
                                    <WithUserTag tag="pal">
                                        <th>{t('admin:cumulatives.catches')}</th>
                                        <th>{t('admin:cumulatives.meanSize')}</th>
                                        <th>{t('admin:cumulatives.weight')}</th>
                                    </WithUserTag>
                                    {species.code === 'EL1' && (
                                        <React.Fragment>
                                            <th>{t('common:consumption')}</th>
                                            <th>{t('common:restocking')}</th>
                                        </React.Fragment>
                                    )}
                                </tr>
                            </thead>
                            <tbody>{cumulativeRows}</tbody>
                        </table>
                    </div>
                </div>
            </OrgChangeObserver>
        );
    }
}
