import React from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { formatPhoneNumber } from '@SUPPORT/utils';

@withRouter
@inject('store')
@withTranslation(['common'])
@observer
export class BuyerInfoSubSection extends React.Component {
    render() {
        const buyers = this.props.store.buyers.list || [];
        const buyer = buyers.find((buyer) => buyer.id === parseInt(this.props.match.params.id, 10));
        if (!buyer) {
            return null;
        }

        const { t } = this.props;
        return (
            <div className="panel selected">
                <div className="scroll">
                    <form>
                        <div>
                            <label>{t('common:company')} :</label>
                            <p>{buyer.name}</p>
                        </div>
                        <div>
                            <label>{t('common:admin')} :</label>
                            <p>{buyer.ownerName}</p>
                        </div>
                        <h4>Informations</h4>
                        <div>
                            <label>{t('common:email')} :</label>
                            <p>
                                <a href={`mailto:${buyer.companyEmail}`}>{buyer.companyEmail}</a>
                            </p>
                        </div>
                        <div>
                            <label>{t('common:phone')} :</label>
                            <p>{formatPhoneNumber(buyer.phoneNumber)}</p>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
