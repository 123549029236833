import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';

import { SpeciesSeasonsSubSection } from './SpeciesSeasonsSubSection';
import { NavigationLink } from '@COMPONENTS/common/NavigationLink';

@withRouter
@inject('store')
@withTranslation(['common'])
@observer
export class SpeciesSection extends React.Component {
    render() {
        const species = this.props.store.species.byCode(this.props.match.params.code);
        if (!species) {
            return null;
        }

        const { t } = this.props;
        return (
            <div className="selected">
                <div className="header noBottom">
                    <h3>
                        {species.name} ({species.code})<span>{t('common:species')}</span>
                    </h3>
                    <nav>
                        <ul>
                            <NavigationLink
                                to={`/things/species/${species.code}/seasons`}
                                label={t('common:campaigns')}
                                tag="li"
                            />
                        </ul>
                    </nav>
                </div>
                <div className="panels">
                    <Route path="/things/species/:code/seasons" component={SpeciesSeasonsSubSection} />
                    <Route
                        exact
                        path="/things/species/:code"
                        render={() => <Redirect to={`/things/species/${species.code}/seasons`} />}
                    />
                </div>
            </div>
        );
    }
}
