import React from 'react';

export class GroupsSection extends React.Component {
    render() {
        return (
            <div className="selected">
                <div className="header">
                    <h3>
                        OP<span>Groups</span>
                    </h3>
                </div>
                <div className="scroll">
                    <form>
                        <h4>Info</h4>
                        <div>
                            <label>A faire</label>
                            <label htmlFor="123456">Specie A</label>
                        </div>
                        <h4>Species</h4>
                        <div>
                            <input type="checkbox" id="123456" />
                            <label htmlFor="123456">Specie A</label>
                        </div>
                        <div>
                            <input type="checkbox" id="223456" />
                            <label htmlFor="223456">Specie B</label>
                        </div>
                        <div>
                            <input type="checkbox" id="323456" />
                            <label htmlFor="323456">Specie C</label>
                        </div>
                    </form>
                </div>
                <div className="exit">
                    <div />
                    <div>
                        <button>Annuler</button>
                        <button>Sauver</button>
                    </div>
                </div>
            </div>
        );
    }
}
