import React from 'react';

export class SMSSection extends React.Component {
    render() {
        return (
            <div className="selected">
                <div className="header">
                    <h3>Packs SMS</h3>
                </div>
                <div className="scroll">
                    <form>
                        <div className="full">
                            <p>
                                Les SMS envoyés par les pêcheurs sont inclus dans le forfait xx SMS par pêcheurs = xxx
                                SMS blah blah blah (à voir avec Luc et Guillaume)
                            </p>
                        </div>
                        <h4>Packs de SMS achetés</h4>
                        <div className="full">
                            <select>
                                <option>Année 2017</option>
                                <option>Année 2016</option>
                            </select>
                        </div>
                        <div>
                            <ul>
                                <li>Pack 5 000 SMS (xx €) - 12 avril 2017</li>
                                <li>Pack 10 000 SMS (xx €) - 21 juin 2017</li>
                            </ul>
                        </div>
                        <h4>Votre situation actuelle</h4>
                        <div className="aligned full">
                            <label>Il vous reste </label>
                            <p>
                                <span className="warning">391 SMS</span> (1 242 utilisés dans les trois derniers mois)
                            </p>
                        </div>
                        <h4>Commander des packs SMS</h4>
                        <div className="aligned full">
                            <label>Sélectionner le montant désiré</label>
                            <input type="text" className="twoDigits" value="1" readOnly />
                            <select>
                                <option>Pack 1 000 SMS (xx €)</option>
                                <option>Pack 5 000 SMS (xx €)</option>
                                <option>Pack 10 000 SMS (xx €)</option>
                            </select>
                        </div>
                        <div>
                            <button>Commander (xx €)</button>
                        </div>
                        <div className="full">
                            <label>Votre commande a été envoyée.</label>
                            <p>Vous allez recevoir un devis par email.</p>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
