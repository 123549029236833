import React from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Route, Redirect, withRouter, matchPath } from 'react-router-dom';

import { SelectorLink } from '@COMPONENTS/common/SelectorLink';
import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

@withRouter
@inject('store')
@withTranslation(['common'])
@observer
export class LicensesSelector extends React.Component {
    _update = () => {
        const { user } = this.props.store.session.info;
        if (user.type === 'manager') {
            // this.props.store.licenses.listAll();
        } else {
            this.props.store.licenses.listOrgLicenseSeasons().then(() => {
                const { seasons } = this.props.store.licenses;
                const firstSeason = seasons.length > 0 ? seasons[0] : null;
                this._selectSeason(firstSeason);
            });
        }
    };

    _onSeasonChange = (evt) => {
        this._selectSeason(evt.target.value);
    };

    _selectSeason = (season) => {
        this.props.store.licenses.setCurrentSeason(season);
        this.props.store.licenses.listOrgLicenses().then(() => this.props.history.replace(this.props.baseUrl));
    };

    _createLicense = () => {
        this.props.store.openNewLicenseDialog();
    };

    render() {
        const firstLicense = this.props.store.licenses.list.length > 0 ? this.props.store.licenses.list[0] : null;
        const defaultSelection = firstLicense ? `${this.props.baseUrl}/${firstLicense.id}` : null;

        const match = matchPath(this.props.location.pathname, { path: `${this.props.baseUrl}/:id`, exact: true });
        const selectedLicense = match ? this.props.store.licenses.byId(parseInt(match.params.id, 10)) : null;

        const { t } = this.props;
        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="list subList show">
                    <dl className="show">
                        <div className="burger">
                            <span />
                        </div>
                        <h6>{selectedLicense ? selectedLicense.name : ''}</h6>
                        <dt>{t('common:licenses')}</dt>
                        <dd className="noSelection">
                            <select onChange={this._onSeasonChange} value={this.props.store.licenses.currentSeason}>
                                {this.props.store.licenses.seasons.map((season) => (
                                    <option value={season} key={season}>
                                        Campagne {season}
                                    </option>
                                ))}
                            </select>
                        </dd>
                        {this.props.store.licenses.list.map((license) => (
                            <SelectorLink
                                label={license.name}
                                to={`${this.props.baseUrl}/${license.id}`}
                                key={license.id}
                            />
                        ))}

                        {defaultSelection && (
                            <Route exact path={this.props.baseUrl} render={() => <Redirect to={defaultSelection} />} />
                        )}
                    </dl>
                    <ul className="toolbar">
                        {this.props.allowCreation && <li className="plus" onClick={this._createLicense} />}
                    </ul>
                </div>
            </OrgChangeObserver>
        );
    }
}
