import { types, flow } from 'mobx-state-tree';

import { SessionStore } from '@STORES/common/session';
import { Country } from '@STORES/common/country';
import { AppStore } from '@STORES/common/app';
import { OrgsStore } from '@STORES/common/org';
import { SpeciesStore } from '@STORES/common/species';
import { ZonesStore } from '@STORES/common/zones';
import { GearsStore } from '@STORES/common/gears';
import { LicensesStore } from '@STORES/common/license';
import { TechniquesStore } from '@STORES/common/technique';
import { StatisticsStore } from '@STORES/common/stats';
import { DeclarationDialogStore } from '@STORES/common/declaration.dialog';
import { DeclarationWizardStore } from '@STORES/common/declaration.wizard';
import { PescaliceStore } from '@STORES/common/pescalice';
import { LandingPortsStore } from '@STORES/common/landingPort';

import { ErrorsOverviewStore } from './overview.errors';
import { SpeciesOverviewStore } from './overview.species';

import { AdminsStore } from './people.admins';
import { UsersStore } from './people.users';
import { BuyersStore } from './people.buyers';

import { SeasonsStore } from './things.seasons';
import { BoatsStore } from './things.boats';
import { CompaniesStore } from './things.companies';
import { RulesStore } from './things.rules';

import { SettingsStore } from './tools.settings';

import { api } from '@SUPPORT/api';

const AdminStore = types
    .model('AdminStore', {
        app: types.optional(AppStore, {}),
        session: types.optional(SessionStore, {}),
        countries: types.array(Country),
        species: types.optional(SpeciesStore, {}),
        zones: types.optional(ZonesStore, {}),
        gears: types.optional(GearsStore, {}),
        techniques: types.optional(TechniquesStore, {}),
        orgs: types.optional(OrgsStore, {}),
        stats: types.optional(StatisticsStore, {}),

        declarationDialog: types.optional(DeclarationDialogStore, {}),
        declarationWizard: types.optional(DeclarationWizardStore, {}),

        errorsOverview: types.optional(ErrorsOverviewStore, {}),
        speciesOverview: types.optional(SpeciesOverviewStore, {}),
        admins: types.optional(AdminsStore, {}),
        users: types.optional(UsersStore, {}),
        buyers: types.optional(BuyersStore, {}),

        companies: types.maybeNull(CompaniesStore),
        seasons: types.optional(SeasonsStore, {}),
        boats: types.maybeNull(BoatsStore),
        landingPorts: types.maybeNull(LandingPortsStore),
        licenses: types.optional(LicensesStore, {}),
        rules: types.optional(RulesStore, {}),
        pescalice: types.maybeNull(PescaliceStore),

        settings: types.optional(SettingsStore, {})
    })
    .actions((self) => {
        return {
            loadInitialData: flow(function* () {
                const { user, org } = self.session.info;

                const response = yield api.listCountries();
                self.countries = response.data || [];

                // if (user.type === 'superadmin' || user.hasTag('pap')) {
                //     yield self.orgs.listAll();
                //     self.session.selectOrg(user.type === 'superadmin' ? self.orgs.list[0] : self.orgs.byId(user.orgId));
                // }

                if (user.hasTag('psc')) {
                    self.companies = CompaniesStore.create({});
                    self.pescalice = PescaliceStore.create({});
                    self.pescalice.loadInitialData();
                }

                if (org && org.hasFeature('boats')) {
                    self.boats = BoatsStore.create({});
                    yield self.boats.loadInitialData();
                }

                if ((org && org.hasFeature('landing_ports')) || user.type === 'superadmin') {
                    self.landingPorts = LandingPortsStore.create({});
                    yield self.landingPorts.loadInitialData();
                }

                if (org && org.hasTag('pal')) {
                    yield self.techniques.listAll();
                } else {
                    yield self.gears.listAll();
                }

                yield Promise.all([self.species.listAll(), self.species.listOrgSpecies(), self.zones.listAll()]);

                self.app.setReady();
            }),

            selectOrg: flow(function* (org) {
                self.session.selectOrg(org);
                self.rules.reset();
                yield Promise.all([self.species.listOrgSpecies(), self.zones.listAll()]);
            })
        };
    });

export const store = AdminStore.create({});
