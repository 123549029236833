import React from 'react';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

export class GearsSection extends React.Component {
    render() {
        return (
            <div className="selected">
                <div className="header">
                    <h3>
                        Nets<span>Gears</span>
                    </h3>
                </div>
                <div className="scroll">
                    <div className="block">
                        <h2>
                            <input type="checkbox" id="535454" />
                            <label htmlFor="535454">Coque COC</label>
                            <SVGObject objectId="arrowIco" className="picto active" titme="Voir l'espèce" />
                        </h2>
                        <form>
                            <div className="aligned">
                                <label>Gear type: </label>
                                <select>
                                    <option>Aucun</option>
                                    <option>Type 1</option>
                                    <option>Type 2</option>
                                    <option>Type 3</option>
                                    <option>Type 4</option>
                                    <option>Type 5</option>
                                </select>
                            </div>
                            <div>
                                <button>+ Add a new gear type</button>
                            </div>
                        </form>
                    </div>

                    <div className="block">
                        <h2>
                            <input type="checkbox" id="535455" />
                            <label htmlFor="535455">Palourde croisée d&apos;Europe (PAL)</label>
                            <SVGObject objectId="arrowIco" className="picto active codeRed" titme="Voir l'espèce" />
                        </h2>
                        <form>
                            <div className="aligned">
                                <label>Gear type: </label>
                                <select>
                                    <option>Aucun</option>
                                    <option>Type 1</option>
                                    <option>Type 2</option>
                                    <option>Type 3</option>
                                    <option>Type 4</option>
                                    <option>Type 5</option>
                                </select>
                            </div>
                            <div>
                                <button>+ Add a new Gear type</button>
                            </div>
                        </form>
                    </div>

                    <div className="block add">
                        <h3>Add a new specie +</h3>
                    </div>
                </div>
                <div className="exit">
                    <div />
                    <div>
                        <button>Annuler</button>
                        <button>Sauver</button>
                    </div>
                </div>
            </div>
        );
    }
}
