import React from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';

import { i18n } from '@SUPPORT/i18n';
import { formatLocaleDate, formatPhoneNumber, capitalize } from '@SUPPORT/utils';

import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

function errorMessage(code) {
    // Note: this could be factored to a one liner but the i18n parser extractor
    // would not pick up the different keys so we specify them all explicitely.
    switch (code) {
        case 'unknown_person':
            return i18n.t('admin:sms-errors.error.unknown_person');
        case 'unknown_org':
            return i18n.t('admin:sms-errors.error.unknown_org');
        case 'unknown_zone':
            return i18n.t('admin:sms-errors.error.unknown_zone');
        case 'disallowed_zone':
            return i18n.t('admin:sms-errors.error.disallowed_zone');
        case 'unknown_species':
            return i18n.t('admin:sms-errors.error.unknown_species');
        case 'disallowed_species':
            return i18n.t('admin:sms-errors.error.disallowed_species');
        case 'unknown_gear':
            return i18n.t('admin:sms-errors.error.unknown_gear');
        case 'missing_cr':
            return i18n.t('admin:sms-errors.error.missing_cr');
        case 'too_many_decl':
            return i18n.t('admin:sms-errors.error.too_many_decl');
        case 'out_of_season':
            return i18n.t('admin:sms-errors.error.out_of_season');
        case 'malformed_text':
            return i18n.t('admin:sms-errors.error.malformed_text');
        case 'low_value':
            return i18n.t('admin:sms-errors.error.low_value');
        case 'high_value':
            return i18n.t('admin:sms-errors.error.high_value');
        case 'total_value_exceeded':
            return i18n.t('admin:sms-errors.error.total_value_exceeded');
        case 'transaction_processed':
            return i18n.t('admin:sms-errors.error.transaction_processed');
        case 'closed_zone':
            return i18n.t('admin:sms-errors.error.closed_zone');
        case 'other':
            return i18n.t('admin:sms-errors.error.other');
        default:
            return code;
    }
}

@withRouter
class UserLink extends React.Component {
    _goToUser = (evt) => {
        evt.preventDefault();
        this.props.history.push(`/people/user/${this.props.error.userId}`);
    };

    render() {
        return (
            <a href="#" onClick={this._goToUser}>
                {this.props.error.usersLastName.toUpperCase()}&nbsp;{this.props.error.usersFirstName}
            </a>
        );
    }
}

@inject('store')
@withTranslation(['admin', 'common'])
@observer
export class ErrorsSection extends React.Component {
    _update = () => {
        this.props.store.errorsOverview.listErrors();
    };

    _updateLimit = (evt) => {
        const limit = parseInt(evt.target.value, 10);
        this.props.store.errorsOverview.setLimit(limit);
    };

    _goToUser = (evt) => {
        evt.preventDefault();
        this.props.history.push(`/admin/`);
    };

    render() {
        // Group errors by day
        let currentKey = null;
        const errors = this.props.store.errorsOverview.list.reduce((days, err) => {
            const key = formatLocaleDate(err.eventDate, 'yyyyMMdd');
            let day = days.length > 0 ? days[days.length - 1] : null;
            if (!day || key != currentKey) {
                day = { key, errors: [] };
                days.push(day);
                currentKey = key;
            }

            day.errors.push(err);

            return days;
        }, []);

        const { t } = this.props;
        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="selected styledList">
                    <div className="header">
                        <h3>{t('admin:sms-errors.title')}</h3>
                        <form>
                            <div className="aligned">
                                <label>{t('admin:sms-errors.display')}</label>
                                <select onChange={this._updateLimit} value={this.props.store.errorsOverview.limit}>
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="500">500</option>
                                </select>
                                <label>{t('admin:sms-errors.last-errors')}</label>
                            </div>
                        </form>
                    </div>
                    <div className="scroll">
                        <ul>
                            {errors.map((day) => (
                                <li className="codeRed" key={day.key}>
                                    <header>
                                        <h2>
                                            {capitalize(
                                                formatLocaleDate(
                                                    day.errors[0].eventDate,
                                                    t('admin:sms-errors.date-format')
                                                )
                                            )}
                                        </h2>
                                    </header>
                                    {day.errors.map((error) => (
                                        <figure key={error.id}>
                                            <span className="whenWhere">
                                                <p>
                                                    {formatLocaleDate(
                                                        error.eventDate,
                                                        t('admin:sms-errors.time-format')
                                                    )}
                                                </p>
                                                <p>
                                                    <UserLink error={error} />
                                                </p>
                                                <p>
                                                    <a href={formatPhoneNumber(error.usersMobileNumber, 'RFC3966')}>
                                                        {formatPhoneNumber(error.usersMobileNumber)}
                                                    </a>
                                                </p>
                                            </span>
                                            <span className="what">
                                                <ul className="table TwoCols">
                                                    <li>
                                                        <p className="intro">{errorMessage(error.type)}</p>
                                                    </li>
                                                    <li>
                                                        <p>{error.sourceText}</p>
                                                    </li>
                                                </ul>
                                            </span>
                                        </figure>
                                    ))}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </OrgChangeObserver>
        );
    }
}
