import React from 'react';
import { withRouter } from 'react-router';
import { Route, Redirect } from 'react-router-dom';

import { RulesOpeningsSubSection } from './RulesOpeningsSubSection';
import { RulesQuotasSubSection } from './RulesQuotasSubSection';
import { RulesGearsSubSection } from './RulesGearsSubSection';

import { NavigationLink } from '@COMPONENTS/common/NavigationLink';

@withRouter
export class RulesSection extends React.Component {
    render() {
        return (
            <div className="selected">
                <div className="header noBottom">
                    <h3>Règles de gestion</h3>
                    <nav>
                        <ul>
                            <NavigationLink to="/things/rules/openings" label="Ouvertures" tag="li" />
                            <NavigationLink to="/things/rules/quotas" label="Quotas" tag="li" />
                            <NavigationLink to="/things/rules/gears" label="Engins" tag="li" />
                        </ul>
                    </nav>
                </div>
                <div className="panels">
                    <Route path="/things/rules/openings" component={RulesOpeningsSubSection} />
                    <Route path="/things/rules/quotas" component={RulesQuotasSubSection} />
                    <Route path="/things/rules/gears" component={RulesGearsSubSection} />

                    <Route exact path="/things/rules" render={() => <Redirect to="/things/rules/openings" />} />
                </div>
            </div>
        );
    }
}
