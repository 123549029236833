import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { i18n } from '@SUPPORT/i18n';
import { formatPhoneNumber } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

@inject('store')
@withTranslation(['admin', 'common'])
@observer
class AdminCard extends React.Component {
    get _isMainContact() {
        return this.props.store.session.info.org.contactId === this.props.admin.id;
    }

    _selectAsMainContact = () => {
        if (this.props.admin.type !== 'admin') {
            return;
        }

        const { user } = this.props.store.session.info;
        if (this._isMainContact || !user.isNotRestricted || user.hasTag('dpma')) {
            return;
        }

        this.props.store.admins
            .updateOrgMainContact(this.props.admin.id)
            .then(() => this.props.store.session.info.org.setContactId(this.props.admin.id));
    };

    _userTypeLabel(type) {
        const { t } = this.props;
        switch (type) {
            case 'admin':
                return t('common:admin');
            case 'reader':
                return i18n.t('common:reader');
            default:
                return '-';
        }
    }

    render() {
        const classes = classNames({ selected: this._isMainContact });
        const { t } = this.props;
        return (
            <div className={classes} onClick={this._selectAsMainContact}>
                <SVGObject objectId="checkIco" className="check" />
                <h3>{this.props.admin.fullName(false)}</h3>
                {this.props.specifyType && <h5>{this._userTypeLabel(this.props.admin.type)}</h5>}
                <div>
                    <a href={`mailto:${this.props.admin.email}`}>{this.props.admin.email}</a>
                </div>
                <div>
                    <label>{t('common:mobile-number')} :</label>
                    <p>{formatPhoneNumber(this.props.admin.mobileNum1)}</p>
                </div>
                <div>
                    <label>{t('common:phone-number')} :</label>
                    <p>{formatPhoneNumber(this.props.admin.phoneNum)}</p>
                </div>
            </div>
        );
    }
}

// <div>
//     <label>Alertes envoyées par</label>
// </div>
// <div>
//     <input type="checkbox" id="1939484" />
//     <label htmlFor="1939484">{t('common:sms')}</label>
//     <input type="checkbox" id="2939484" />
//     <label htmlFor="2939484">{t('common:email')}</label>
// </div>

@inject('store')
@withTranslation(['admin', 'common'])
@observer
export class AdminsSection extends React.Component {
    _update = () => {
        this.props.store.admins.listOrgAdmins();
    };

    render() {
        const admins = this.props.store.admins.filteredList;
        const hasReader = admins.some((admin) => admin.type === 'reader');

        const { t } = this.props;
        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="selected">
                    <div className="header">
                        <h3>
                            {t('common:admins')}
                            <span>({t('admin:admins.visible-infos')})</span>
                        </h3>
                    </div>
                    <div className="scroll">
                        <form className="bigRadioButton">
                            {admins.map((admin) => (
                                <AdminCard admin={admin} key={admin.id} specifyType={hasReader} />
                            ))}
                        </form>
                    </div>
                </div>
            </OrgChangeObserver>
        );
    }
}
