import { types, getRoot, flow } from 'mobx-state-tree';

import { api } from '@SUPPORT/api';

export const SpeciesOverviewStore = types.model('SpeciesOverviewStore', {}).actions((self) => {
    const { app, session } = getRoot(self);

    return {
        listSeasons: flow(function* (species) {
            app.setBusy();
            try {
                const info = yield session.getSession();
                const response = yield api.listSpeciesSeasons(info.org.id, species);
                return response.data || [];
            } finally {
                app.setBusy(false);
            }
        }),

        listSpeciesCumulatives: flow(function* (species, seasonId) {
            app.setBusy();
            try {
                const info = yield session.getSession();
                const response = yield api.listSpeciesCumulatives(info.org.id, species, seasonId);
                return response.data || [];
            } finally {
                app.setBusy(false);
            }
        }),

        downloadDeclarationsCSV: flow(function* (species, seasonId) {
            const info = yield session.getSession();
            api.downloadDeclarationsCSV(info.org.id, species, seasonId);
        })
    };
});
