import React from 'react';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

import { SpeciesList } from './RulesSpeciesList';
import { GearsList } from './RulesGearsList';

@inject('store')
@observer
export class RulesGearsSubSection extends React.Component {
    @observable loading = false;

    @action setLoading(loading = true) {
        this.loading = loading;
    }

    _update = () => {
        this.setLoading();
        this.props.store.rules
            .listRules('gear')
            .then(() => {
                this.setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                this.setLoading(false);
            });
    };

    _newRule = (evt) => {
        evt.preventDefault();
        this.props.store.rules.setShowRuleDialog(true, 'gear');
    };

    render() {
        const rules = this.props.store.rules.rules.get('gear') || [];
        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="panel">
                    <div className="scroll">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Zone</th>
                                    <th>Espèce(s)</th>
                                    <th>Engin(s) interdit(s)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rules.map((rule) => (
                                    <GearRuleRow rule={rule} store={this.props.store} key={rule.id} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="exit">
                        <div>
                            <button disabled={this.loading} onClick={this._newRule}>
                                Nouvelle règle
                            </button>
                        </div>
                        <div></div>
                    </div>
                </div>
            </OrgChangeObserver>
        );
    }
}

function GearRuleRow(props) {
    const { rule, store } = props;
    const edit = () => store.rules.setShowRuleDialog(true, rule.type, rule);

    return (
        <tr>
            <td>
                <SVGObject className="tool" objectId="editIcon" onClick={edit} />
            </td>
            <td>{`${rule.zone.code} (${rule.zone.name})`}</td>
            <td>
                <SpeciesList species={rule.species} store={store} />
            </td>
            <td>
                <GearsList gears={rule.info.excludedGears} />
            </td>
        </tr>
    );
}
