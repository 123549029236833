/* eslint-disable require-atomic-updates */
import { types, getRoot, flow, applySnapshot } from 'mobx-state-tree';

import { Zone } from '@STORES/common/zones';
import { Gear } from '@STORES/common/gears';
import { Species } from '@STORES/common/species';

import { api } from '@SUPPORT/api';

const OpeningRuleInfo = types
    .model('OpeningRuleInfo', {
        startDate: types.Date,
        endDate: types.Date
    })
    .preProcessSnapshot((snapshot) => ({
        ...snapshot,
        startDate: typeof snapshot.startDate === 'string' ? new Date(snapshot.startDate) : snapshot.startDate,
        endDate: typeof snapshot.endDate === 'string' ? new Date(snapshot.endDate) : snapshot.endDate
    }));

const QuotaRuleInfo = types
    .model('OpeningRuleInfo', {
        startDate: types.maybeNull(types.Date),
        endDate: types.maybeNull(types.Date),
        dailyQuota: types.maybeNull(types.number),
        totalQuota: types.maybeNull(types.number)
    })
    .preProcessSnapshot((snapshot) => ({
        ...snapshot,
        startDate: typeof snapshot.startDate === 'string' ? new Date(snapshot.startDate) : snapshot.startDate,
        endDate: typeof snapshot.endDate === 'string' ? new Date(snapshot.endDate) : snapshot.endDate
    }));

const GearRuleInfo = types.model('GearRuleInfo', {
    excludedGears: types.array(types.reference(Gear))
});

function makeInfo(type, data) {
    const obj = JSON.parse(data);
    switch (type) {
        case 'opening':
            return OpeningRuleInfo.create(obj);
        case 'quota':
            return QuotaRuleInfo.create(obj);
        case 'gear':
            return GearRuleInfo.create(obj);
        default:
            return {};
    }
}

const OrgRule = types
    .model('OrgRule', {
        id: types.identifierNumber,
        type: types.enumeration('RuleType', ['opening', 'quota', 'gear']),
        zone: types.reference(Zone),
        species: types.array(types.reference(Species)),
        info: types.union(OpeningRuleInfo, QuotaRuleInfo, GearRuleInfo)
    })
    .preProcessSnapshot(
        (snapshot) =>
            snapshot && {
                ...snapshot,
                info: typeof snapshot.info === 'string' ? makeInfo(snapshot.type, snapshot.info) : snapshot.info
            }
    );

const RulesList = types.array(OrgRule);

export const RulesStore = types
    .model('RulesStore', {
        rules: types.map(RulesList),

        showRuleDialog: false,
        ruleDialogType: types.maybeNull(types.string),
        ruleDialogData: types.maybeNull(types.reference(OrgRule))
    })
    .actions((self) => {
        return {
            reset: () => {
                self.rules.clear();
            },

            setShowRuleDialog: (show, ruleType = null, rule = null) => {
                const { app } = getRoot(self);
                app.setModal(show);
                self.showRuleDialog = show;
                self.ruleDialogType = ruleType;
                self.ruleDialogData = rule ? rule.id : null;
            },

            listRules: flow(function* (ruleType, force = false) {
                if (!force && self.rules.has(ruleType)) {
                    return self.rules.get(ruleType);
                }

                const { app, session } = getRoot(self);
                app.setBusy();

                try {
                    const info = yield session.getSession();
                    const response = yield api.listOrgRules(info.org.id, ruleType);
                    self.rules.set(ruleType, RulesList.create(response.data));
                } catch (err) {
                    console.log(err);
                } finally {
                    app.setBusy(false);
                }
            }),

            createRule: flow(function* (ruleType, data) {
                const { app, session } = getRoot(self);
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    const response = yield api.createRule(info.org.id, ruleType, data);
                    const rule = OrgRule.create(response.data);
                    if (self.rules.has(rule.type)) {
                        self.rules.get(rule.type).push(rule);
                    } else {
                        self.rules.set(rule.type, [rule]);
                    }
                } finally {
                    app.setBusy(false);
                }
            }),

            updateRule: flow(function* (ruleType, ruleId, data) {
                const { app, session } = getRoot(self);
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    const response = yield api.updateRule(info.org.id, ruleType, ruleId, data);
                    const rule = self.rules.get(ruleType).find((rule) => rule.id === ruleId);
                    applySnapshot(rule, response.data);
                } finally {
                    app.setBusy(false);
                }
            }),

            deleteRule: flow(function* (ruleType, ruleId) {
                const { app, session } = getRoot(self);
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    yield api.deleteRule(info.org.id, ruleType, ruleId);
                    const rules = self.rules.get(ruleType);
                    self.rules.set(
                        ruleType,
                        rules.filter((rule) => rule.id !== ruleId)
                    );
                } finally {
                    app.setBusy(false);
                }
            })
        };
    });
