import React from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';

import { ErrorsSection } from './overview/ErrorsSection';
import { ConflictsSection } from './overview/ConflictsSection';
import { SpeciesOvwSection } from './overview/SpeciesSection';

import { AdminsSection } from './people/AdminsSection';
import { UsersSelector } from './people/UsersSelector';
import { UsersSection } from './people/UsersSection';
import { UserSelector } from './people/UserSelector';
import { UserSection } from './people/UserSection';
import { NewUserDialog } from './people/NewUserDialog';
import { GroupsSelector } from './people/GroupsSelector';
import { GroupsSection } from './people/GroupsSection';
import { BuyersSelector } from './people/BuyersSelector';
import { BuyersSection } from './people/BuyersSection';

import { CompaniesSection } from './things/CompaniesSection';
import { CompaniesSelector } from './things/CompaniesSelector';
import { CompanySection } from './things/CompanySection';
import { CompanySelector } from './things/CompanySelector';
import { NewCompanyDialog } from './things/NewCompanyDialog';
import { NewCompanyMemberDialog } from './things/NewCompanyMemberDialog';
import { ZonesSection } from './things/ZonesSection';
import { BoatsSection } from './things/BoatsSection';
import { BoatsSelector } from './things/BoatsSelector';
import { BoatSelector } from './things/BoatSelector';
import { BoatSection } from './things/BoatSection';
import { NewBoatDialog } from './things/NewBoatDialog';
import { BoatOwnerRemovalDialog } from './things/BoatOwnerRemovalDialog';
import { SpeciesSection } from './things/SpeciesSection';
import { GearsSection } from './things/GearsSection';
import { SMSSection } from './things/SMSSection';
import { LicensesSection } from './things/LicensesSection';
import { RulesSection } from './things/RulesSection';
import { RuleDialog } from './things/RuleDialog';

import { AlertsSection } from './tools/AlertsSection';

import { ErrorBoundary } from '@COMPONENTS/common/ErrorBoundary';
import { Header } from '@COMPONENTS/common/Header';
import { Copyright } from '@COMPONENTS/common/Copyright';
import { SelectorLink } from '@COMPONENTS/common/SelectorLink';
import { SpeciesSelector } from '@COMPONENTS/common/SpeciesSelector';
import { GearsSelector } from '@COMPONENTS/common/GearsSelector';
import { LicensesSelector } from '@COMPONENTS/common/LicensesSelector';
import { AlertDialog } from '@COMPONENTS/common/AlertDialog';
import { UserPicDialog } from '@COMPONENTS/common/UserPicDialog';
import { ModalBackground } from '@COMPONENTS/common/ModalBackground';

import { WithFeature } from '@COMPONENTS/common/WithFeature';
import { WithPermission } from '@COMPONENTS/common/WithPermission';
import { WithUserTag } from '@COMPONENTS/common/WithUserTag';
import { WithUserLevel } from '@COMPONENTS/common/WithUserLevel';
import { UnlessUserTag } from '@COMPONENTS/common/UnlessUserTag';
import { DeclarationDialog } from '@COMPONENTS/common/DeclarationDialog';
import { DeclarationWizard } from '@COMPONENTS/common/DeclarationWizard';
import { StatisticsSection } from '@COMPONENTS/common/StatisticsSection';
import { StatisticsFiltersSelector } from '@COMPONENTS/common/StatisticsFiltersSelector';

@inject('store')
@withTranslation(['admin', 'common'])
@observer
export class AdminRoot extends React.Component {
    componentDidMount() {
        this.props.store.session.getSession().then(this.props.store.loadInitialData);
    }

    _selectOrg = (evt) => {
        const org = this.props.store.orgs.byId(parseInt(evt.target.value, 10));
        if (!org) {
            return;
        }

        this.props.store.selectOrg(org);
    };

    render() {
        if (this.props.store.session.info === null) {
            return null;
        }

        const user = this.props.store.session.info.user;

        const { t } = this.props;
        return (
            <ErrorBoundary>
                <Header key="header" />

                {this.props.store.app.isReady ? (
                    <div id="content" key="content">
                        <div className="list">
                            <dl className="mainView show">
                                <div className="burger">
                                    <span />
                                </div>

                                <h6>{t('admin:main-selector.sms-errors')}</h6>

                                {user.type === 'superadmin' && !user.hasTag('dpma') && (
                                    <React.Fragment>
                                        <dt>{t('admin:main-selector.tools')}</dt>
                                        <SelectorLink label={t('common:alerts')} to="/tools/alerts" />
                                    </React.Fragment>
                                )}

                                {user.canSwitchOrg && (
                                    <React.Fragment>
                                        <dt>{t('admin:main-selector.organisation')}</dt>
                                        <dd className="noSelection">
                                            <select
                                                value={this.props.store.session.info.org.id}
                                                onChange={this._selectOrg}
                                            >
                                                {this.props.store.orgs.list.map((org) => (
                                                    <option value={org.id} key={org.code}>
                                                        {org.name} ({org.code})
                                                    </option>
                                                ))}
                                            </select>
                                        </dd>
                                    </React.Fragment>
                                )}

                                <dt>{t('admin:main-selector.overview')}</dt>
                                {user.isNotRestricted && (
                                    <UnlessUserTag tag="pal">
                                        <SelectorLink
                                            label={t('admin:main-selector.sms-errors')}
                                            to="/overview/errors"
                                        />
                                    </UnlessUserTag>
                                )}
                                {user.isNotRestricted && (
                                    <SelectorLink
                                        label={t('admin:main-selector.conflicts')}
                                        to="/overview/conflicts"
                                        className="not-implemented"
                                    />
                                )}
                                {user.isNotRestricted && (
                                    <SelectorLink label={t('common:catches')} to="/overview/species" />
                                )}
                                <SelectorLink label={t('common:stats')} to="/overview/stats" />

                                <dt>{t('admin:main-selector.users')}</dt>
                                <SelectorLink
                                    label={t('common:fishermen')}
                                    to="/people/users"
                                    also="/people/user/:id"
                                />
                                <SelectorLink label={t('common:admins')} to="/people/admins" />
                                <SelectorLink
                                    label={t('admin:main-selector.groups')}
                                    to="/people/groups"
                                    className="not-implemented"
                                />
                                <WithPermission name="VIEW_BUYERS_INFO">
                                    <SelectorLink
                                        label={t('admin:main-selector.buyers')}
                                        to="/people/buyer"
                                        also="/people/buyer/:id/info"
                                    />
                                </WithPermission>

                                <UnlessUserTag tag="dpma">
                                    <dt>{t('admin:main-selector.things')}</dt>
                                    <WithUserTag tag="psc">
                                        <SelectorLink
                                            label={t('common:companies')}
                                            to="/things/companies"
                                            also="/things/company"
                                        />
                                    </WithUserTag>
                                    <SelectorLink label={t('common:zones-full')} to="/things/zones" />
                                    {user.isNotRestricted && !user.hasOnlyTag('pap') && (
                                        <SelectorLink label={t('common:species-plural')} to="/things/species" />
                                    )}
                                    <SelectorLink
                                        label={t('common:gears')}
                                        to="/things/gears"
                                        className="not-implemented"
                                    />
                                    <WithFeature name="boats">
                                        <SelectorLink
                                            label={t('common:boats')}
                                            to="/things/boats"
                                            also="/things/boat/:id"
                                        />
                                    </WithFeature>
                                    <WithUserTag tag="pap">
                                        <SelectorLink
                                            label={
                                                user.hasTag('psc')
                                                    ? t('admin:main-selector.licenses-pap')
                                                    : t('admin:main-selector.licenses')
                                            }
                                            to="/things/license"
                                        />
                                        <SelectorLink label="Règles de gestion" to="/things/rules" />
                                    </WithUserTag>
                                    <SelectorLink
                                        label={t('admin:main-selector.sms-packs')}
                                        to="/things/sms"
                                        className="not-implemented"
                                    />

                                    <WithUserTag tag="psc">
                                        <dd className="noSelection separator" />
                                        <dt>{t('common:pescalice')} - PEP</dt>
                                        <SelectorLink label={t('common:todos')} to="/pescalice/todos-pep" />
                                        <SelectorLink
                                            label={t('admin:main-selector.licenses')}
                                            to="/pescalice/licenses-pep"
                                            also="/pescalice/license-pep"
                                        />

                                        <dt>{t('common:pescalice')} - PAP</dt>
                                        <SelectorLink label={t('common:todos')} to="/pescalice/todos" />
                                        <SelectorLink
                                            label="Timbres"
                                            to="/pescalice/licenses-pap"
                                            also="/pescalice/license-pap"
                                        />
                                    </WithUserTag>
                                </UnlessUserTag>
                            </dl>
                            <ul className="toolbar" />
                        </div>

                        {user.isNotRestricted && (
                            <Route
                                path="/overview/species"
                                render={() => <SpeciesSelector baseUrl="/overview/species" disableWhenNoDecl />}
                            />
                        )}
                        <Route path="/overview/stats" component={StatisticsFiltersSelector} />
                        {user.isNotRestricted && (
                            <Route
                                path="/people/users"
                                render={() => <UsersSelector baseUrl="/people/users" altUrl="/people/user" />}
                            />
                        )}
                        <Route
                            path="/people/user"
                            render={() => <UserSelector baseUrl="/people/user" altUrl="/people/users" />}
                        />
                        <Route path="/people/groups" component={GroupsSelector} />
                        <Route path="/things/species" render={() => <SpeciesSelector baseUrl="/things/species" />} />
                        <Route path="/things/gears" component={GearsSelector} />
                        <WithUserTag tag="pap">
                            <Route
                                path="/things/license"
                                render={() => <LicensesSelector baseUrl="/things/license" />}
                            />
                        </WithUserTag>

                        <WithPermission name="VIEW_BUYERS_INFO">
                            <Route path="/people/buyer" component={BuyersSelector} />
                        </WithPermission>

                        <WithUserTag tag="psc">
                            <Route
                                path="/things/companies"
                                render={() => (
                                    <CompaniesSelector baseUrl="/things/companies" altUrl="/things/company" />
                                )}
                            />
                            <Route
                                path="/things/company"
                                render={() => <CompanySelector baseUrl="/things/company" altUrl="/things/companies" />}
                            />
                        </WithUserTag>

                        <WithFeature name="boats">
                            <Route
                                path="/things/boats"
                                render={() => <BoatsSelector baseUrl="/things/boats" altUrl="/things/boat" />}
                            />
                            <Route
                                path="/things/boat"
                                render={() => <BoatSelector baseUrl="/things/boat" altUrl="/things/boats" />}
                            />
                        </WithFeature>

                        <div className="listResult selected">
                            <div className="contentArea">
                                {user.isNotRestricted && <Route path="/overview/errors" component={ErrorsSection} />}
                                {user.isNotRestricted && (
                                    <Route path="/overview/conflicts" component={ConflictsSection} />
                                )}
                                {user.isNotRestricted && (
                                    <Route path="/overview/species/:code" component={SpeciesOvwSection} />
                                )}
                                <Route path="/overview/stats" component={StatisticsSection} />

                                <Route path="/people/admins" component={AdminsSection} />
                                <Route path="/people/users" render={() => <UsersSection altUrl="/people/user" />} />
                                <Route
                                    path="/people/user/:id"
                                    render={() => <UserSection rootUrl="/things" baseUrl="/people/user" />}
                                />
                                <Route path="/people/groups" component={GroupsSection} />

                                <WithPermission name="VIEW_BUYERS_INFO">
                                    <Route path="/people/buyer/:id" component={BuyersSection} />
                                </WithPermission>

                                <Route path="/things/zones" component={ZonesSection} />
                                {user.isNotRestricted && (
                                    <Route path="/things/species/:code" component={SpeciesSection} />
                                )}
                                <Route path="/things/gears" component={GearsSection} />
                                <Route path="/things/sms" component={SMSSection} />
                                <WithUserTag tag="pap">
                                    <Route path="/things/license/:id" component={LicensesSection} />
                                    <Route path="/things/rules" component={RulesSection} />
                                </WithUserTag>

                                <WithUserTag tag="psc">
                                    <Route
                                        path="/things/companies"
                                        render={() => (
                                            <CompaniesSection baseUrl="/things/companies" altUrl="/things/company" />
                                        )}
                                    />
                                    <Route
                                        path="/things/company/:id"
                                        render={() => (
                                            <CompanySection baseUrl="/things/company" altUrl="/things/companies" />
                                        )}
                                    />
                                </WithUserTag>

                                <WithFeature name="boats">
                                    <Route path="/things/boats" render={() => <BoatsSection altUrl="/things/boat" />} />
                                    <Route
                                        path="/things/boat/:id"
                                        render={() => <BoatSection baseUrl="/things/boat" altUrl="/things/boats" />}
                                    />
                                </WithFeature>

                                <WithUserLevel level="superadmin">
                                    <Route path="/tools/alerts" component={AlertsSection} />
                                </WithUserLevel>
                            </div>
                            <Copyright />
                        </div>

                        <Route
                            exact
                            path="/"
                            render={() => <Redirect to={user.isNotRestricted ? '/people/users' : '/people/user'} />}
                        />
                        {!user.isNotRestricted && (
                            <Route exact path="/people/users" render={() => <Redirect to="/people/user" />} />
                        )}
                    </div>
                ) : null}

                <ModalBackground display={this.props.store.app.inModal} />
                <AlertDialog />
                <DeclarationDialog declaration={this.props.store.declarationDialog.declaration} />
                <DeclarationWizard
                    rootDeclaration={this.props.store.declarationWizard.rootDeclaration}
                    captures={this.props.store.declarationWizard.captures}
                />
                <NewUserDialog baseUrl="/people" />
                <UserPicDialog />
                <WithFeature name="boats">
                    <NewBoatDialog baseUrl="/things" />
                    <BoatOwnerRemovalDialog />
                </WithFeature>
                <WithUserTag tag="pap">
                    <RuleDialog />
                </WithUserTag>
                <WithUserTag tag="psc">
                    <NewCompanyDialog baseUrl="/things" />
                    <NewCompanyMemberDialog />
                </WithUserTag>
            </ErrorBoundary>
        );
    }
}
