import React from 'react';

const MAX = 5;

export function SpeciesList(props) {
    let { species } = props;
    if (species.length === 0) {
        return null;
    }

    if (species.length === 1 && species[0].code !== '***') {
        const sp = species[0];
        return (
            <React.Fragment>
                <i className="yellow">{sp.code}</i>
                {sp.name}
            </React.Fragment>
        );
    }

    const { store } = props;
    const sourceList = store.species.orgList.slice().sort((sp1, sp2) => sp1.code.localeCompare(sp2.code));
    if (species.length === 1 && species[0].code === '***') {
        species = sourceList.slice();
    }

    const list = species.slice(0, MAX).map((sp) => (
        <i className="yellow" title={sp.name} key={sp.code}>
            {sp.code}
        </i>
    ));

    if (species.length > MAX) {
        const totalCount = sourceList.length;
        const remainderCount = totalCount - list.length;
        const title = [];
        for (let i = MAX; i < totalCount; i++) {
            const sp = sourceList[i];
            title.push(sp.code);
        }

        list.push(
            <i className="yellow" title={title.join(', ')} key="remainder">
                {remainderCount === 1 ? '+1 espèce' : `+${remainderCount} espèces`}
            </i>
        );
    }

    return list;
}
