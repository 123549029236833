import { types, getRoot, flow } from 'mobx-state-tree';

import { api } from '@SUPPORT/api';

import { UserType } from '@STORES/common/user';

const Admin = types
    .model('Admin', {
        id: types.identifierNumber,
        identifier: types.string,
        firstName: types.string,
        lastName: types.string,
        email: types.maybeNull(types.string),
        mobileNum1: types.maybeNull(types.string),
        mobileNum2: types.maybeNull(types.string),
        phoneNum: types.maybeNull(types.string),
        orgId: types.maybeNull(types.number),
        tags: types.maybeNull(types.array(types.string)),
        type: UserType
    })
    .views((self) => {
        return {
            hasTag(tag) {
                return self.tags && self.tags.includes(tag);
            },

            hasAnyOfTag(tags = []) {
                if (!self.tags && tags.length === 0) {
                    return true;
                }
                if (self.tags && self.tags.length === 0 && tags.length === 0) {
                    return true;
                }

                return self.tags && self.tags.some((tag) => tags.includes(tag));
            },

            fullName(lastNameFirst = true) {
                if (lastNameFirst) {
                    return `${self.lastName.toUpperCase()} ${self.firstName}`;
                }

                return `${self.firstName} ${self.lastName.toUpperCase()}`;
            }
        };
    });

export const AdminsStore = types
    .model('AdminsStore', {
        list: types.array(Admin)
    })
    .views((self) => {
        const { session } = getRoot(self);
        return {
            get filteredList() {
                const sessionUserTags = session.info.user.tags || [];
                return self.list.filter((admin) => admin.hasAnyOfTag(sessionUserTags));
            }
        };
    })
    .actions((self) => {
        const { app, session } = getRoot(self);

        return {
            listOrgAdmins: flow(function* () {
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    const includeReaders = info.org.hasTag('pap') && info.user.hasTag('pap');
                    const response = yield api.listOrgAdmins(info.org.id, includeReaders);
                    self.list = response.data ? response.data.map((data) => Admin.create(data)) : [];
                    return self.list;
                } finally {
                    app.setBusy(false);
                }
            }),

            updateOrgMainContact: flow(function* (contactId) {
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    return yield api.updateOrgMainContact(info.org.id, contactId);
                } finally {
                    app.setBusy(false);
                }
            })
        };
    });
