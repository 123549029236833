import { types, getRoot, flow, applySnapshot } from 'mobx-state-tree';

import { Org } from '@STORES/common/org';

import { api } from '@SUPPORT/api';

export const Season = types
    .model('Season', {
        id: types.identifierNumber,
        orgId: types.reference(Org),
        name: types.string,
        startDate: types.Date,
        endDate: types.Date
    })
    .preProcessSnapshot(
        (snapshot) =>
            snapshot && {
                ...snapshot,
                startDate: typeof snapshot.startDate === 'string' ? new Date(snapshot.startDate) : snapshot.startDate,
                endDate: typeof snapshot.endDate === 'string' ? new Date(snapshot.endDate) : snapshot.endDate
            }
    );

export const DraftSeason = types.model('DraftSeason', {
    name: types.maybeNull(types.string),
    startDate: types.maybeNull(types.Date),
    endDate: types.maybeNull(types.Date),
    isDraft: true
});

const SeasonsList = types.array(Season);

export const SeasonsStore = types
    .model('SeasonsStore', {
        list: types.optional(SeasonsList, []),
        draft: types.maybeNull(DraftSeason)
    })
    .views((self) => {
        return {
            byId: (id) => {
                return self.list.find((season) => season.id === id);
            }
        };
    })
    .actions((self) => {
        const { app, session } = getRoot(self);
        return {
            listForSpecies: flow(function* (species, justReturn = false) {
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    const response = yield api.listSeasons(info.org.id, species);
                    const seasons = response.data ? SeasonsList.create(response.data) : [];
                    if (!justReturn) {
                        self.list = seasons;
                    }
                    return seasons;
                } finally {
                    app.setBusy(false);
                }
            }),

            createSeason: flow(function* (season) {
                app.setBusy();
                try {
                    const info = yield session.getSession();
                    season.orgId = info.org.id;
                    const response = yield api.createSeason(season);
                    self.list.unshift(response.data);
                } finally {
                    app.setBusy(false);
                }
            }),

            updateSeason: flow(function* (season) {
                app.setBusy();
                try {
                    const response = yield api.updateSeason(season);
                    const mySeason = self.byId(season.id);
                    applySnapshot(mySeason, response.data);
                } finally {
                    app.setBusy(false);
                }
            }),

            deleteSeason: flow(function* (season) {
                app.setBusy();
                try {
                    yield api.deleteSeason(season);
                    self.list = self.list.filter((s) => s.id !== season.id);
                } finally {
                    app.setBusy(false);
                }
            }),

            newDraft: () => {
                if (self.draft) {
                    return;
                }

                self.draft = DraftSeason.create();
            },

            clearDraft: () => {
                self.draft = null;
            }
        };
    });
