import React from 'react';
import { observable } from 'mobx';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

@withRouter
@inject('store')
@withTranslation(['common'])
@observer
export class LicensesSection extends React.Component {
    @observable licensees = [];

    componentDidMount() {
        this._update();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this._update();
        }
    }

    _update = () => {
        const licenseId = this.props.match.params.id;
        this.props.store.licenses.listLicensees(licenseId).then((licensees) => {
            this.licensees = licensees;
        });
    };

    _downloadCSV = (evt) => {
        evt.preventDefault();
        const licenseId = this.props.match.params.id;
        this.props.store.licenses.downloadAttributionsCSV(licenseId);
    };

    render() {
        const license = this.props.store.licenses.byId(parseInt(this.props.match.params.id, 10));
        if (!license) {
            return null;
        }

        const attributed = this.licensees.length;
        const contingent = license.maxActive;

        const { t } = this.props;
        return (
            <div className="selected">
                <div className="header">
                    <h3>
                        {license.name}
                        <span>
                            {contingent >= 0
                                ? t('admin:licenses.attributions', { count: attributed, contingent })
                                : t('admin:licenses.no_attribution', { count: attributed })}
                        </span>
                    </h3>
                    <div className="toolkit">
                        <div>
                            <button onClick={this._downloadCSV}>{t('common:download-csv')}</button>
                        </div>
                    </div>
                </div>
                <div className="scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Identifiant unique</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.licensees.map((user) => (
                                <tr key={user.id}>
                                    <td>{user.fullName()}</td>
                                    <td>{user.uniqueIdentifier}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
