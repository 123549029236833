import React from 'react';
import { observable } from 'mobx';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';

import { formatLocaleDate, userInitials, formatDeclarationValue, formatMoneyValue } from '@SUPPORT/utils';
import { TRANSACTION_STATUS } from '@SUPPORT/utils';

import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

@withRouter
@withTranslation(['common'])
class TransactionRow extends React.Component {
    _jumpToUser = (evt) => {
        evt.preventDefault();
        this.props.history.push(`/people/user/${this.props.transaction.declarationUserId}/history`);
    };

    render() {
        const { transaction } = this.props;
        const total = (transaction.declarationValue / (transaction.unit === 'kg' ? 1000 : 1)) * transaction.price;
        const { t } = this.props;
        return (
            <tr className={transaction.status}>
                <td>
                    <span>{t('common:name')} :</span>
                    <a href="#" onClick={this._jumpToUser}>
                        {transaction.declarationFirstName} {transaction.declarationLastName}
                    </a>
                </td>
                <td>
                    <span>{t('common:date')} :</span>
                    {formatLocaleDate(transaction.creationDate, 'P p')}
                </td>
                <td className="status">
                    <span>{t('common:status')} :</span>
                    {TRANSACTION_STATUS['fr'][transaction.status]}
                </td>
                <td>
                    <span>{t('common:collector')} :</span>
                    {userInitials(transaction.buyerFirstName, transaction.buyerLastName)}
                </td>
                <td>
                    <span>{t('common:species')} :</span>
                    <i className="yellow">{transaction.declarationSpecies}</i>
                </td>
                <td>
                    <span>{t('common:weight')} :</span>
                    {formatDeclarationValue(transaction.declarationValue)}
                </td>
                <td>
                    <span>{t('common:price')} :</span>
                    {transaction.price ? `${formatMoneyValue(transaction.price / 100)}/${transaction.unit}` : '-'}
                </td>
                <td>
                    <span>{t('common:total')} :</span>
                    <b>{transaction.price ? formatMoneyValue(total / 100) : '-'}</b>
                </td>
            </tr>
        );
    }
}

@withRouter
@inject('store')
@withTranslation(['common'])
@observer
export class BuyerTransactionsSubSection extends React.Component {
    @observable transactions = [];

    componentDidUpdate(prevProps) {
        if (this.props.match.params.code !== prevProps.match.params.code) {
            this._update();
        }
    }

    _update = () => {
        this.props.store.buyers
            .listTransactions(this.props.match.params.id)
            .then((transactions) => (this.transactions = transactions));
    };

    _jumpToUser() {}

    render() {
        const { t } = this.props;
        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="panel">
                    <div className="scroll">
                        <table>
                            <thead>
                                <tr>
                                    <th>{t('common:name')}</th>
                                    <th>{t('common:date')}</th>
                                    <th>{t('common:status')}</th>
                                    <th>{t('common:collector')}</th>
                                    <th>{t('common:species')}</th>
                                    <th>{t('common:weight')}</th>
                                    <th>{t('common:price')}</th>
                                    <th>{t('common:total')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.transactions.map((tr) => (
                                    <TransactionRow transaction={tr} key={tr.id} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </OrgChangeObserver>
        );
    }
}
