import React from 'react';
import classNames from 'classnames';
import ReactForm from 'mobx-react-form';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';

import { validator } from '@SUPPORT/validator';
import { formatLocaleDate, capitalize } from '@SUPPORT/utils';

import { EmptyContent } from '@COMPONENTS/common/EmptyContent';
import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@inject('store')
@withTranslation(['admin', 'common'])
@observer
class SeasonBlock extends React.Component {
    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    species: { value: props.species },
                    name: { type: 'text', rules: 'required', value: props.season.name },
                    startDate: {
                        type: 'text',
                        rules: 'required|fdate',
                        value: props.season.startDate ? formatLocaleDate(props.season.startDate, 'P') : ''
                    },
                    endDate: {
                        type: 'text',
                        rules: 'required|fdate',
                        value: props.season.endDate ? formatLocaleDate(props.season.endDate, 'P') : ''
                    }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    _delete = (evt) => {
        evt.preventDefault();
        const { t, season } = this.props;
        this.props.store.app.alert({
            title: capitalize(t('common:confirmation')),
            message1: t('admin:campaigns-section.confirm-delete', { name: season.name }),
            label1: t('common:delete'),

            validationCallback: () => {
                this.props.store.seasons.deleteSeason(this.props.season);
                this.props.store.app.dismissAlert();
            }
        });
    };

    _reset = (evt) => {
        evt.preventDefault();
        if (this.props.season.isDraft) {
            this.props.store.seasons.clearDraft();
        } else {
            this.form.reset();
        }
    };

    _save = (evt) => {
        evt.preventDefault();

        let apiCall, season;
        if (this.props.season.isDraft) {
            season = this.form.values();
            apiCall = this.props.store.seasons.createSeason;
        } else {
            season = { ...getSnapshot(this.props.season), ...this.form.values() };
            apiCall = this.props.store.seasons.updateSeason;
        }

        const startDate = new Date(`${season.startDate.replace(/(\d\d)\/(\d\d)\/(\d\d\d\d)/, '$3-$2-$1')}T00:00:00`);
        season.startDate = startDate.toISOString();
        const endDate = new Date(`${season.endDate.replace(/(\d\d)\/(\d\d)\/(\d\d\d\d)/, '$3-$2-$1')}T23:59:59`);
        season.endDate = endDate.toISOString();

        apiCall(season);

        if (this.props.season.isDraft) {
            this.props.store.seasons.clearDraft();
        } else {
            this.form.init({
                species: this.props.species,
                name: season.name,
                startDate: formatLocaleDate(startDate, 'P'),
                endDate: formatLocaleDate(endDate, 'P')
            });
        }
    };

    render() {
        const { t, season } = this.props;
        return (
            <div className="block">
                <h3>{season.isDraft ? t('admin:campaigns-section.new-campaign') : season.name}</h3>
                <div>
                    <label>{t('admin:campaigns-section.campaign-name')}</label>
                    <input {...this.form.select('name').bind()} />
                </div>
                <div>
                    <label>{t('admin:campaigns-section.start-of-campaign')}</label>
                    <input {...this.form.select('startDate').bind()} className="date" />
                </div>
                <div>
                    <label>{t('admin:campaigns-section.end-of-campaign')}</label>
                    <input {...this.form.select('endDate').bind()} className="date" />
                </div>
                <div className="exit">
                    <div>
                        {!season.isDraft && (
                            <button className="codeOrange" onClick={this._delete}>
                                {t('common:delete')}
                            </button>
                        )}
                    </div>
                    <div>
                        <button
                            onClick={this._reset}
                            disabled={!season.isDraft && !this.form.isDirty}
                            className={classNames({ withGlyph: !season.isDraft })}
                            title={t('admin:campaigns-section.reset-title')}
                        >
                            {season.isDraft ? t('common:cancel') : <SVGObject objectId="undo" />}
                        </button>
                        <button
                            onClick={this._save}
                            disabled={!this.form.isDirty || (this.form.isDirty && !this.form.isValid)}
                        >
                            {season.isDraft ? t('common:create') : t('common:save')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

@withRouter
@inject('store')
@withTranslation(['admin', 'common'])
@observer
export class SpeciesSeasonsSubSection extends React.Component {
    _update = () => {
        this.props.store.seasons.listForSpecies(this.props.match.params.code);
    };

    _new = (evt) => {
        evt.preventDefault();
        this.props.store.seasons.newDraft();
    };

    render() {
        const { t } = this.props;
        return (
            <OrgChangeObserver onChange={this._update}>
                <div className="panel">
                    {this.props.store.seasons.list.length === 0 && this.props.store.seasons.draft === null ? (
                        <EmptyContent />
                    ) : (
                        <div className="scroll">
                            <form>
                                {this.props.store.seasons.draft && (
                                    <SeasonBlock
                                        season={this.props.store.seasons.draft}
                                        species={this.props.match.params.code}
                                        key="draft.season"
                                    />
                                )}
                                {this.props.store.seasons.list.map((season) => (
                                    <SeasonBlock
                                        season={season}
                                        species={this.props.match.params.code}
                                        key={season.id}
                                    />
                                ))}
                            </form>
                        </div>
                    )}
                    <div className="exit">
                        <div>
                            <button disabled={this.props.store.seasons.draft !== null} onClick={this._new}>
                                {t('admin:campaigns-section.new-campaign')}
                            </button>
                        </div>
                        <div />
                    </div>
                </div>
            </OrgChangeObserver>
        );
    }
}
