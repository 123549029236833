import { types, getRoot, flow } from 'mobx-state-tree';

import { api } from '@SUPPORT/api';

export const SettingsStore = types.model('SettingsStore', {}).actions((self) => {
    const { app } = getRoot(self);

    return {
        fetch: flow(function* (key, orgId, tag) {
            app.setBusy();
            try {
                const response = yield api.getSettings(key, orgId, tag);
                return response.data || {};
            } finally {
                app.setBusy(false);
            }
        }),

        update: flow(function* (key, orgId, tag, data) {
            app.setBusy();
            try {
                yield api.updateSettings(key, orgId, tag, data);
            } finally {
                app.setBusy(false);
            }
        })
    };
});
