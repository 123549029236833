import React from 'react';
import { inject } from 'mobx-react';

@inject('store')
export class WithFeature extends React.Component {
    render() {
        if (!this.props.store.session.info.org || !this.props.store.session.info.org.hasFeature(this.props.name)) {
            return null;
        }

        return this.props.children;
    }
}
